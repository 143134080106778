import React, { useState } from 'react';
import { Button, Table, Modal, Form } from 'react-bootstrap';

const HighRiskTransComponent = () => {
  const [show, setShow] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [pauseReason, setPauseReason] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const highRiskTransactions = [
    { id: 1, transactionId: 'TRX123456', user: 'John Doe', amount: '$5000', status: 'Pending', date: '2024-06-01' },
    { id: 2, transactionId: 'TRX123457', user: 'Jane Smith', amount: '$3000', status: 'Pending', date: '2024-06-02' },
    { id: 3, transactionId: 'TRX123458', user: 'Mike Johnson', amount: '$7000', status: 'Pending', date: '2024-06-03' },
    // Add more transactions as needed
  ];

  const handlePause = (transaction) => {
    setSelectedTransaction(transaction);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const handleSave = () => {
    console.log(`Pausing transaction with id: ${selectedTransaction.id}, Reason: ${pauseReason}`);
    // Add logic to handle pausing the transaction
    setShow(false);
  };

  const filteredTransactions = highRiskTransactions.filter(transaction =>
    transaction.transactionId.toLowerCase().includes(searchQuery.toLowerCase()) &&
    (!startDate || transaction.date >= startDate) &&
    (!endDate || transaction.date <= endDate)
  );

  return (
    <div style={{ marginLeft: '20%', marginTop: '4%', width: '75%' }}>
      <h1>High Risk Transactions</h1>
      
      <Form.Group controlId="searchInput" style={{ marginBottom: '20px' }}>
        <Form.Control
          type="text"
          placeholder="Search by Transaction ID..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ marginBottom: '10px' }}
        />
        <Form.Label>Filter by Date Range:</Form.Label>
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <Form.Control
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            style={{ marginRight: '10px' }}
          />
          <Form.Control
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      </Form.Group>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Transaction ID</th>
            <th>User</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredTransactions.map((transaction) => (
            <tr key={transaction.id}>
              <td>{transaction.id}</td>
              <td>{transaction.transactionId}</td>
              <td>{transaction.user}</td>
              <td>{transaction.amount}</td>
              <td>{transaction.status}</td>
              <td>{transaction.date}</td>
              <td>
                <Button variant="warning" onClick={() => handlePause(transaction)}>
                  Pause
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Pause Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="pauseReason">
              <Form.Label>Reason for Pausing</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter reason"
                value={pauseReason}
                onChange={(e) => setPauseReason(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default HighRiskTransComponent;
