import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import TransStatusComponent from '../Components/TransStatusComponent'

function TransSatus() {
  return (
    <div>
    
      <SideNav/>
      <TransStatusComponent/>
    </div>
  )
}

export default TransSatus
