import React from 'react'

const SanctionedComp = () => {
  return (
    <div style={{ marginTop: '5%', marginLeft: '20%', overflow: 'auto', height: '100vh' }}>
    <h1>Under Construction</h1>
    </div>
  )
}

export default SanctionedComp