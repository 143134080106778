import React from 'react'
import MNavComp from '../../Components/ManagerComp/MNavComp'
import MDashComponent from '../../Components/ManagerComp/MDashComponent'

function ManagerDash() {
  return (
    <div>
      <MNavComp/>
      <MDashComponent/>
    </div>
  )
}

export default ManagerDash
