import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import AddComponent from '../Components/AddComponent'

function Add() {
  return (
    <div>

<SideNav/>
<AddComponent/>
    </div>
  )
}

export default Add
