import React, { useState, useEffect } from 'react';
import { Button, Modal, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';
import axios from 'axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  overflow: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function ViewInnerComp() {
  const [openModal, setOpenModal] = useState(false);
  const [transfers, setTransfers] = useState([]);
  const [filteredTransfers, setFilteredTransfers] = useState([]);
  const [searchAgentId, setSearchAgentId] = useState('');
  const [currencies, setCurrencies] = useState({});
  const [error, setError] = useState('');

  const token = localStorage.getItem('admin_token');

  useEffect(() => {
    // Fetch currencies
    const fetchCurrencies = async () => {
      try {
        const response = await axios.get('https://api.nkolozi.com/api/Admin/currencies', {
          headers: { 'Authorization': `Bearer ${token}` },
        });
        const currencyMap = response.data.data.reduce((acc, currency) => {
          acc[currency.id] = currency.name;
          return acc;
        }, {});
        setCurrencies(currencyMap);
      } catch (error) {
        console.error('Failed to fetch currencies:', error);
        setError('Failed to fetch currencies');
      }
    };
    fetchCurrencies();
  }, [token]);

  const handleOpenModal = async () => {
    try {
      const response = await axios.get('https://api.nkolozi.com/api/Admin/all-inner-branch-transfers', {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      const sortedTransfers = response.data.inner_branch_transfers.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setTransfers(sortedTransfers);
      setFilteredTransfers(sortedTransfers);
      setOpenModal(true);
    } catch (error) {
      console.error('Failed to fetch transfers:', error);
      if (error.response) {
        setError(`Error: ${error.response.data.message || 'An unexpected error occurred'}`);
      } else {
        setError(`Error: ${error.message || 'An unexpected error occurred'}`);
      }
    }
  };

  const handleCloseModal = () => setOpenModal(false);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchAgentId(value);

    if (value) {
      const filtered = transfers.filter(transfer => transfer.agent_id.includes(value));
      setFilteredTransfers(filtered);
    } else {
      setFilteredTransfers(transfers);
    }
  };

  return (
    <div>
      {error && <Typography color="error">{error}</Typography>}
      <Button variant="contained" color="primary" onClick={handleOpenModal}>
        View Inner Branch Transfers
      </Button>
      <Modal open={openModal} onClose={handleCloseModal} aria-labelledby="modal-title" aria-describedby="modal-description">
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">Inner Bank Transfers</Typography>
          <TextField
            label="Search by Agent ID"
            value={searchAgentId}
            onChange={handleSearchChange}
            fullWidth
            margin="normal"
          />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Agent ID</TableCell>
                  <TableCell>Branch ID</TableCell>
                  <TableCell>Back Office ID</TableCell>
                  <TableCell>Amount Deposited</TableCell>
                  <TableCell>Currency</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Updated At</TableCell>
                  <TableCell>Shortfall</TableCell>
                  <TableCell>Nkolozi Stack</TableCell>
                  <TableCell>User Stack</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTransfers.map((transfer) => (
                  <TableRow key={transfer.id}>
                    <TableCell>{transfer.id}</TableCell>
                    <TableCell>{transfer.agent_id}</TableCell>
                    <TableCell>{transfer.branch_id}</TableCell>
                    <TableCell>{transfer.back_office_id}</TableCell>
                    <TableCell>{transfer.amount_deposited}</TableCell>
                    <TableCell>{currencies[transfer.currency_id] || 'Unknown'}</TableCell>
                    <TableCell>{new Date(transfer.created_at).toLocaleString()}</TableCell>
                    <TableCell>{new Date(transfer.updated_at).toLocaleString()}</TableCell>
                    <TableCell>{transfer.shortfall}</TableCell>
                    <TableCell>{transfer.nkolozi_stack}</TableCell>
                    <TableCell>{transfer.user_stack}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
}

export default ViewInnerComp;
