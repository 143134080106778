import React from 'react'
import InnerBankComp from '../Components/InnerBankComp'
import SideNav from '../Components/SideNav' 

function InnerBank() {
  return (
    <div>
      <SideNav/>
      <InnerBankComp/>
    </div>
  )
}

export default InnerBank
