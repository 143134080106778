import React from 'react';

function ComplianceComponent() {
  return (
    <div style={{ marginLeft: '20%', marginTop: '4%', width: '75%' }}>
      <h1>Compliance Policies</h1>

      <div style={{ marginBottom: '20px' }}>
        <h3>Customer Due Diligence (CDD)</h3>
        <p>
          We verify and understand our customers to prevent illegal activities. We use
          secure methods to confirm their identities.
        </p>
      </div>

      <div>
        <h3>Transaction Monitoring</h3>
        <p>
          We carefully watch transactions to detect suspicious behavior and protect our
          customers from harm.
        </p>
      </div>

      <div>
        <h3>Compliance Reporting</h3>
        <p>
          We submit necessary reports to regulatory authorities, keeping them informed
          about our operations.
        </p>
      </div>
    </div>
  );
}

export default ComplianceComponent;
