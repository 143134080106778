import React from 'react'

function UsersComponent() {
  return (
    <div>
      <h1>Users</h1>
    </div>
  )
}

export default UsersComponent
