import React from 'react'

const RbzComp = () => {
  return (
    <div style={{marginTop:'50px', marginLeft:'20%'}}>
        <h1>RBZ Form</h1>
    </div>
  )
}

export default RbzComp