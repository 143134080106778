import React, { useState, useEffect } from 'react';
import { Button, Modal, Box, Typography, TextField, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import axios from 'axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  overflow: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function InnerBankComp() {
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [agents, setAgents] = useState([]);
  const [branches, setBranches] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [formData, setFormData] = useState({
    agent_id: '',
    branch_id: '',
    total_amount: '',
    nkolozi_stack: '',
    user_stack: '',
    currency_id: '',
  });
  const [error, setError] = useState('');

  const token = localStorage.getItem('admin_token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch agents with user type "agent"
        const agentsResponse = await axios.get('https://api.nkolozi.com/api/Admin/employees', {
          headers: { 'Authorization': `Bearer ${token}` },
        });
        console.log('Agents response:', agentsResponse.data); // Log to understand structure
        if (Array.isArray(agentsResponse.data.data)) {
          const filteredAgents = agentsResponse.data.data.filter(employee => employee.user_type === 'agent');
          setAgents(filteredAgents);
        } else if (Array.isArray(agentsResponse.data)) {
          const filteredAgents = agentsResponse.data.filter(employee => employee.user_type === 'agent');
          setAgents(filteredAgents);
        } else {
          console.error('Unexpected agents response structure:', agentsResponse);
        }

        // Fetch branches
        const branchesResponse = await axios.get('https://api.nkolozi.com/api/Admin/branchs', {
          headers: { 'Authorization': `Bearer ${token}` },
        });
        console.log('Branches response:', branchesResponse.data); // Log to understand structure
        if (Array.isArray(branchesResponse.data.data)) {
          setBranches(branchesResponse.data.data);
        } else if (Array.isArray(branchesResponse.data)) {
          setBranches(branchesResponse.data);
        } else {
          console.error('Unexpected branches response structure:', branchesResponse);
        }

        // Fetch currencies
        const currenciesResponse = await axios.get('https://api.nkolozi.com/api/Admin/currencies', {
          headers: { 'Authorization': `Bearer ${token}` },
        });
        console.log('Currencies response:', currenciesResponse.data); // Log to understand structure
        if (Array.isArray(currenciesResponse.data.data)) {
          setCurrencies(currenciesResponse.data.data);
        } else if (Array.isArray(currenciesResponse.data)) {
          setCurrencies(currenciesResponse.data);
        } else {
          console.error('Unexpected currencies response structure:', currenciesResponse);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setError('Failed to load data. Please try again later.');
      }
    };

    fetchData();
  }, [token]);

  const handleOpenTransferModal = () => setOpenTransferModal(true);
  const handleCloseTransferModal = () => setOpenTransferModal(false);

  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleInnerBankTransfer = async () => {
    try {
      const response = await axios.post(
        'https://api.nkolozi.com/api/Admin/inner-branch-tranfer',
        {
          agent_id: formData.agent_id,
          branch_id: formData.branch_id,
          total_amount: formData.total_amount,
          nkolozi_stack: formData.nkolozi_stack,
          user_stack: formData.user_stack,
          currency_id: formData.currency_id,
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log(formData)
      console.log('Inner bank transfer successful', response.data);
      alert('Inner bank transfer successful', response.data)
      handleCloseTransferModal();
    } catch (error) {
      console.error('Failed to perform inner bank transfer', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
        setError(`Error: ${error.response.data.message || 'An unexpected error occurred'}`);
      } else {
        setError(`Error: ${error.message || 'An unexpected error occurred'}`);
      }
    }
  };

  return (
    <div>
     
      {error && <Typography color="error">{error}</Typography>}
      <Button variant="contained" color="primary" onClick={handleOpenTransferModal}>
        Perform Inner Branch Transfer
      </Button>
      <Modal open={openTransferModal} onClose={handleCloseTransferModal} aria-labelledby="modal-title" aria-describedby="modal-description">
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">Inner Bank Transfer</Typography>
          <form>
            <FormControl fullWidth margin="normal">
              <InputLabel>Agent</InputLabel>
              <Select
                name="agent_id"
                value={formData.agent_id}
                onChange={handleFormChange}
                fullWidth
              >
                {agents.length > 0 ? (
                  agents.map(agent => (
                    <MenuItem key={agent.id} value={agent.id}>
                      {agent.name} {agent.surname}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No agents available</MenuItem>
                )}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel>Branch</InputLabel>
              <Select
                name="branch_id"
                value={formData.branch_id}
                onChange={handleFormChange}
                fullWidth
              >
                {branches.length > 0 ? (
                  branches.map(branch => (
                    <MenuItem key={branch.id} value={branch.id}>
                      {branch.branch_name} - {branch.location}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No branches available</MenuItem>
                )}
              </Select>
            </FormControl>

            <TextField
              label="Total Amount"
              name="total_amount"
              value={formData.total_amount}
              onChange={handleFormChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Nkolozi Stack"
              name="nkolozi_stack"
              value={formData.nkolozi_stack}
              onChange={handleFormChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="User Stack"
              name="user_stack"
              value={formData.user_stack}
              onChange={handleFormChange}
              fullWidth
              margin="normal"
            />

            <FormControl fullWidth margin="normal">
              <InputLabel>Currency</InputLabel>
              <Select
                name="currency_id"
                value={formData.currency_id}
                onChange={handleFormChange}
                fullWidth
              >
                {currencies.length > 0 ? (
                  currencies.map(currency => (
                    <MenuItem key={currency.id} value={currency.id}>
                      {currency.name} ({currency.country})
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No currencies available</MenuItem>
                )}
              </Select>
            </FormControl>

            <Button variant="contained" color="primary" onClick={handleInnerBankTransfer} sx={{ mt: 2 }}>
              Transfer
            </Button>
          </form>
        </Box>
      </Modal>
    </div>
  );
}

export default InnerBankComp;
