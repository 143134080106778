import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Col, Row } from 'react-bootstrap';
import { FaUsers, FaBuilding, FaDollarSign, FaMoneyCheckAlt } from 'react-icons/fa';
import TotalTrans from './TotalTrans';

const MainDash = () => {
  const [totalClients, setTotalClients] = useState(0);
  const [totalAgents, setTotalAgents] = useState(0);
  const [totalBranches, setTotalBranches] = useState(0);
  const [transactionValue, setTransactionValue] = useState(0);
  const token = localStorage.getItem('admin_token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch total clients
        const clientsResponse = await axios.get('https://api.nkolozi.com/api/Admin/clients', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setTotalClients(clientsResponse.data.data.length);

        // Fetch total agents
        const agentsResponse = await axios.get('https://api.nkolozi.com/api/Admin/agents', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setTotalAgents(agentsResponse.data.data.length);

        // Fetch total branches
        const branchesResponse = await axios.get('https://api.nkolozi.com/api/Admin/branchs', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setTotalBranches(branchesResponse.data.data.length);

        // Fetch total transaction value
        const transactionResponse = await axios.get('https://api.nkolozi.com/api/Admin/get-total-transaction-value', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setTransactionValue(transactionResponse.data.sum);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [token]);

  return (
    <div className="maindash">
      <Row>
        <Col md={6} sm={12}>
          <Card className="mb-4 shadow-sm">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5>Total Clients</h5>
                  <h3>{totalClients}</h3>
                </div>
                <FaUsers size={50} className="text-primary" />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} sm={12}>
          <Card className="mb-4 shadow-sm">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5>Total Agents</h5>
                  <h3>{totalAgents}</h3>
                </div>
                <FaUsers size={50} className="text-success" />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={4} sm={12}>
          <Card className="mb-4 shadow-sm">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5>Total Branches</h5>
                  <h3>{totalBranches}</h3>
                </div>
                <FaBuilding size={50} className="text-warning" />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} sm={12}>
          <Card className="mb-4 shadow-sm">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5>Transaction Value</h5>
                  <h3>${transactionValue.toFixed(2)}</h3>
                </div>
                <FaDollarSign size={50} className="text-danger" />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} sm={12}>
          <Card className="mb-4 shadow-sm">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  {/* <h5>Total Nkolozi Stack</h5>
                  <h3>0</h3> Placeholder for now */}
                  <TotalTrans/>
                </div>
                <FaMoneyCheckAlt size={50} className="text-info" />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default MainDash;
