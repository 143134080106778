import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TotalTrans = () => {
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTransactions = async () => {
      const token = localStorage.getItem('admin_token'); // Fetch the token from local storage

      try {
        const response = await axios.get('https://api.nkolozi.com/api/Admin/transactions', {
          headers: {
            'Authorization': `Bearer ${token}`, // Use the token for authentication
          }
        });

        if (response.data && response.data.data) {
          setTotalTransactions(response.data.data.length); // Count the total number of transactions
        }
      } catch (err) {
        console.error('Error fetching transactions:', err);
        setError('Error fetching transactions. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  return (
    <div>
      <h5>Total Transactions</h5>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <h3>{totalTransactions}</h3>
      )}
    </div>
  );
};

export default TotalTrans;
