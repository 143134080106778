import React from 'react';
import { Button, Table } from 'react-bootstrap';

const SuspendedTransComponent = () => {
  

  return (
    <div style={{ marginLeft: '20%', marginTop: '4%', width: '75%' }}>
      <h1>Suspended Transactions</h1>
      
    </div>
  );
};

export default SuspendedTransComponent;
