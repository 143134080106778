import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, Spinner, Alert, Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
import axios from 'axios';

function FloatsComp() {
  const [floatRequests, setFloatRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [currencies, setCurrencies] = useState({});
  const [clients, setClients] = useState({});
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ show: false, message: '', variant: '' });
  const [showModal, setShowModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [otp, setOtp] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchFloatRequestsAndCurrenciesAndClients = async () => {
      const token = localStorage.getItem('admin_token');
      try {
        const [floatResponse, currencyResponse, clientsResponse] = await Promise.all([
          axios.get('https://api.nkolozi.com/api/Admin/all-float-requests', {
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          }),
          axios.get('https://api.nkolozi.com/api/Admin/currencies', {
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          }),
          axios.get('https://api.nkolozi.com/api/Admin/agents', {
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          })
        ]);

        // Map currency id to currency name for easy lookup
        const currencyMap = currencyResponse.data.data.reduce((acc, currency) => {
          acc[currency.id] = currency.name;
          return acc;
        }, {});

        // Map client id to client's full name for easy lookup
        const clientsMap = clientsResponse.data.data.reduce((acc, client) => {
          acc[client.id] = `${client.name} ${client.surname}`;
          return acc;
        }, {});

        // Sort float requests by time descending
        const sortedRequests = floatResponse.data.float_requests.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

        setFloatRequests(sortedRequests);
        setFilteredRequests(sortedRequests);
        setCurrencies(currencyMap);
        setClients(clientsMap);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching float requests, currencies, or clients:', error);
        setAlert({ show: true, message: 'Error fetching data. Please try again.', variant: 'danger' });
        setLoading(false);
      }
    };

    fetchFloatRequestsAndCurrenciesAndClients();
  }, []);

  const initiateGrantFloatRequest = async (floatRequest) => {
    const token = localStorage.getItem('admin_token');
    try {
      await axios.post('https://api.nkolozi.com/api/Admin/initiate-grant-float-request', {
        id: floatRequest.id,
        agent_id: floatRequest.user_id,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      setSelectedRequest(floatRequest);
      setShowModal(true);
    } catch (error) {
      console.error('Error initiating grant float request:', error);
      setAlert({ show: true, message: 'Error initiating grant float request. Please try again.', variant: 'danger' });
    }
  };

  const handleGrantFloatRequest = async () => {
    const token = localStorage.getItem('admin_token');
    try {
      await axios.post('https://api.nkolozi.com/api/Admin/grant-float-request', {
        otp: otp,
        float_request_id: selectedRequest.id,
        agent_id: selectedRequest.user_id,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      setAlert({ show: true, message: 'Float request approved successfully!', variant: 'success' });
      setShowModal(false);
      setOtp('');
    } catch (error) {
      console.error('Error granting float request:', error);
      setAlert({ show: true, message: 'Error granting float request. Please try again.', variant: 'danger' });
      setShowModal(false);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    if (value) {
      const filtered = floatRequests.filter(request => {
        const clientName = clients[request.user_id]?.toLowerCase() || '';
        return clientName.includes(value);
      });
      setFilteredRequests(filtered);
    } else {
      setFilteredRequests(floatRequests);
    }
  };

  // Format date to "8 September 2024"
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    }).format(date);
  };

  return (
    <div style={{ marginTop: '5%', marginLeft: '20%', overflow: 'auto', height: '100vh' }}>
      <h1>Agent Floats</h1>
      {alert.show && (
        <Alert variant={alert.variant} onClose={() => setAlert({ show: false, message: '', variant: '' })} dismissible>
          {alert.message}
        </Alert>
      )}
      <InputGroup className="mb-3" style={{ maxWidth: '400px' }}>
        <InputGroup.Text id="search-addon">Search by Name</InputGroup.Text>
        <FormControl
          placeholder="Enter Name"
          aria-label="Name"
          aria-describedby="search-addon"
          value={searchTerm}
          onChange={handleSearch}
        />
      </InputGroup>
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <div style={{ overflowX: 'auto', maxHeight: '70vh' }}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Client Name</th>
                <th>Currency</th>
                <th>Status</th>
                <th>Topup</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredRequests.map(request => (
                <tr key={request.id}>
                  <td>{request.id}</td>
                  <td>{clients[request.user_id] || 'Unknown User'}</td>
                  <td>{currencies[request.currency_id] || 'Unknown'}</td>
                  <td>{request.status}</td>
                  <td>{request.topup}</td>
                  <td>{formatDate(request.created_at)}</td>
                  <td>{formatDate(request.updated_at)}</td>
                  <td>
                    <Button variant="success" onClick={() => initiateGrantFloatRequest(request)}>
                      Approve
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      {selectedRequest && (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Enter OTP for Approval</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="otpInput">
              <Form.Label>OTP</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleGrantFloatRequest}>
              Approve
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default FloatsComp;
