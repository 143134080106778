import React from 'react';

function RegulatoryComponent() {
  return (
    <div style={{ marginLeft: '20%', marginTop: '4%', width: '75%' }}>
      <h1>Regulatory Information</h1>

      <div style={{ marginBottom: '20px' }}>
        <h3>Regulatory Guidelines</h3>
        <ul>
          <li>Regulations for cross-border transactions</li>
          <li>Anti-money laundering (AML) regulations</li>
          <li>Know Your Customer (KYC) requirements</li>
          <li>Reporting obligations</li>
          <li>Compliance with local financial authorities</li>
        </ul>
      </div>

      <div>
        <h3>Compliance Policies</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero eget mauris
          posuere, nec ultricies lorem tristique. In ac nisl eget eros auctor faucibus.
        </p>
        <p>
          Nullam eget ligula id nisi venenatis consectetur eu ac libero. Aliquam consectetur
          ullamcorper fringilla.
        </p>
      </div>
    </div>
  );
}

export default RegulatoryComponent;
