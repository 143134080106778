import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Spinner, Alert } from 'react-bootstrap';

function LimitComp() {
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState('');
  const [currencies, setCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [cashinLimit, setCashinLimit] = useState('');
  const [loading, setLoading] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [resultType, setResultType] = useState(''); // 'success' or 'danger'

  useEffect(() => {
    const token = localStorage.getItem('admin_token');

    // Fetch agents
    setLoading(true);
    axios.get('https://api.nkolozi.com/api/Admin/agents', {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    })
    .then(response => {
      setAgents(response.data.data);
      setLoading(false);
    })
    .catch(error => {
      console.error('Error fetching agents:', error);
      setLoading(false);
    });

    // Fetch currencies
    setLoading(true);
    axios.get('https://api.nkolozi.com/api/Admin/currencies', {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    })
    .then(response => {
      setCurrencies(response.data.data);
      setLoading(false);
    })
    .catch(error => {
      console.error('Error fetching currencies:', error);
      setLoading(false);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem('admin_token');
    setLoading(true);

    const data = {
      agent_id: selectedAgent,
      currency_id: selectedCurrency,
      cashin_limit: cashinLimit,
    };

    axios.post('https://api.nkolozi.com/api/Admin/set-cashin-limit', data, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    })
    .then(response => {
      console.log(response);
      setResultMessage('Cash-in limit set successfully!');
      setResultType('success');
      setLoading(false);
    })
    .catch(error => {
      console.error('Error setting cash-in limit:', error);
      setResultMessage('Failed to set cash-in limit. Please try again.');
      setResultType('danger');
      setLoading(false);
    });
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">Set Cash In Limit</h1>

      {resultMessage && (
        <Alert variant={resultType} onClose={() => setResultMessage('')} dismissible>
          {resultMessage}
        </Alert>
      )}

      <form onSubmit={handleSubmit} className="w-50 mx-auto">
        <div className="form-group mb-3">
          <label htmlFor="agentSelect">Select Agent</label>
          {loading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <select
              id="agentSelect"
              className="form-control"
              value={selectedAgent}
              onChange={(e) => setSelectedAgent(e.target.value)}
              required
            >
              <option value="" disabled>Select agent</option>
              {agents.map(agent => (
                <option key={agent.id} value={agent.id}>
                  {agent.name} {agent.surname} ({agent.reg_id})
                </option>
              ))}
            </select>
          )}
        </div>

        <div className="form-group mb-3">
          <label htmlFor="currencySelect">Select Currency</label>
          {loading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <select
              id="currencySelect"
              className="form-control"
              value={selectedCurrency}
              onChange={(e) => setSelectedCurrency(e.target.value)}
              required
            >
              <option value="" disabled>Select currency</option>
              {currencies.map(currency => (
                <option key={currency.id} value={currency.id}>
                  {currency.name} ({currency.code})
                </option>
              ))}
            </select>
          )}
        </div>

        <div className="form-group mb-3">
          <label htmlFor="cashinLimit">Cash In Limit</label>
          <input
            type="number"
            id="cashinLimit"
            className="form-control"
            value={cashinLimit}
            onChange={(e) => setCashinLimit(e.target.value)}
            required
          />
        </div>

        <button type="submit" className="btn btn-primary w-100">
          {loading ? <Spinner animation="border" variant="light" /> : 'Set Limit'}
        </button>
      </form>
    </div>
  );
}

export default LimitComp;
