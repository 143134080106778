import React, { useState, useEffect } from 'react';
import { Button, Modal, Box, Typography, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import axios from 'axios';

function TransactionFees() {
  const [transactionFees, setTransactionFees] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [showTransactionFees, setShowTransactionFees] = useState(false);
  const [formData, setFormData] = useState({
    transaction_type: '',
    percentage: '',
    agent_percentage: '',
    transaction_amount_limit: '',
    near_limit_rang: '',
    Daily_Limit: '',
    monthly_limit: '',
  });
  const [updateFormData, setUpdateFormData] = useState({
    transaction_fee_id: '',
    transaction_type: '',
    percentage: '',
  });
  const [error, setError] = useState('');

  useEffect(() => {
    if (showTransactionFees) {
      fetchTransactionFees();
    }
  }, [showTransactionFees]);

  const fetchTransactionFees = async () => {
    const token = localStorage.getItem('admin_token');
    if (!token) {
      setError('Authorization token is missing');
      return;
    }

    try {
      const response = await axios.get('https://api.nkolozi.com/api/Admin/transaction-fees', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setTransactionFees(response.data.data || []);
    } catch (error) {
      console.error('Failed to fetch transaction fees', error);
      setError('Failed to fetch transaction fees');
    }
  };

  const handleOpenAddModal = () => {
    setFormData({
      transaction_type: '',
      percentage: '',
      agent_percentage: '',
      transaction_amount_limit: '',
      near_limit_rang: '',
      Daily_Limit: '',
      monthly_limit: '',
    });
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => setOpenAddModal(false);

  const handleOpenUpdateModal = (fee) => {
    setUpdateFormData({
      transaction_fee_id: fee.id,
      transaction_type: fee.transaction_type,
      percentage: fee.percentage,
      agent_percentage: fee.agent_percentage,
      transaction_amount_limit: fee.transaction_amount_limit,
      near_limit_rang: fee.near_limit_rang,
      Daily_Limit: fee.Daily_Limit,
      monthly_limit: fee.monthly_limit,
    });
    setOpenUpdateModal(true);
  };

  const handleCloseUpdateModal = () => setOpenUpdateModal(false);

  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdateFormChange = (e) => {
    setUpdateFormData({
      ...updateFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddTransactionFee = async () => {
    const token = localStorage.getItem('admin_token');
    if (!token) {
      setError('Authorization token is missing');
      return;
    }

    try {
      const response = await axios.post('https://api.nkolozi.com/api/Admin/add-transaction-fee', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      console.log('Transaction fee added successfully', response.data);
      handleCloseAddModal();
      fetchTransactionFees();
    } catch (error) {
      console.error('Failed to add transaction fee', error);
      setError(`Error: ${error.response?.data?.message || 'An unexpected error occurred'}`);
    }
  };

  const handleUpdateTransactionFee = async () => {
    const token = localStorage.getItem('admin_token');
    if (!token) {
      setError('Authorization token is missing');
      return;
    }

    try {
      const response = await axios.post('https://api.nkolozi.com/api/Admin/update-transaction-fee', updateFormData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      console.log('Transaction fee updated successfully', response.data);
      handleCloseUpdateModal();
      fetchTransactionFees();
    } catch (error) {
      console.error('Failed to update transaction fee', error);
      setError(`Error: ${error.response?.data?.message || 'An unexpected error occurred'}`);
    }
  };



  const handleShowTransactionFees = () => {
    setShowTransactionFees(true);
  };

  return (
    <div>
      {!showTransactionFees && (
        <Button variant="contained" color="primary" onClick={handleShowTransactionFees} startIcon={<AddCircleOutlineIcon />}>
          Transaction Fees
        </Button>
      )}
      {showTransactionFees && (
        <>
          <Button variant="contained" color="primary" onClick={handleOpenAddModal} startIcon={<AddCircleOutlineIcon />}>
            Add Transaction Fee
          </Button>
          <TableContainer component={Paper} style={{ marginTop: '20px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Transaction Type</TableCell>
                  <TableCell>Percentage</TableCell>
                  <TableCell>Agent Percentage</TableCell>
                  <TableCell>Transaction Amount Limit</TableCell>
                  <TableCell>Near Limit Range</TableCell>
                  <TableCell>Daily Limit</TableCell>
                  <TableCell>Monthly Limit</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionFees.map((fee) => (
                  <TableRow key={fee.id}>
                    <TableCell>{fee.transaction_type}</TableCell>
                    <TableCell>{fee.percentage}</TableCell>
                    <TableCell>{fee.agent_percentage}</TableCell>
                    <TableCell>{fee.transaction_amount_limit}</TableCell>
                    <TableCell>{fee.near_limit_rang}</TableCell>
                    <TableCell>{fee.Daily_Limit}</TableCell>
                    <TableCell>{fee.monthly_limit}</TableCell>
                    <TableCell>
                      <Button variant="contained" color="primary" onClick={() => handleOpenUpdateModal(fee)} style={{ marginRight: '10px' }}>
                        Update
                      </Button>
                     
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Modal open={openAddModal} onClose={handleCloseAddModal} aria-labelledby="add-modal-title" aria-describedby="add-modal-description">
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
            }}>
              <Typography id="add-modal-title" variant="h6" component="h2">
                Add Transaction Fee
              </Typography>
              <TextField
                label="Transaction Type"
                name="transaction_type"
                value={formData.transaction_type}
                onChange={handleFormChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Percentage"
                name="percentage"
                value={formData.percentage}
                onChange={handleFormChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Agent Percentage"
                name="agent_percentage"
                value={formData.agent_percentage}
                onChange={handleFormChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Transaction Amount Limit"
                name="transaction_amount_limit"
                value={formData.transaction_amount_limit}
                onChange={handleFormChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Near Limit Range"
                name="near_limit_rang"
                value={formData.near_limit_rang}
                onChange={handleFormChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Daily Limit"
                name="Daily_Limit"
                value={formData.Daily_Limit}
                onChange={handleFormChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Monthly Limit"
                name="monthly_limit"
                value={formData.monthly_limit}
                onChange={handleFormChange}
                fullWidth
                margin="normal"
              />
              <Button variant="contained" color="primary" onClick={handleAddTransactionFee} startIcon={<AddCircleOutlineIcon />} style={{ marginTop: '10px' }}>
                Add Transaction Fee
              </Button>
              {error && (
                <Typography color="error" variant="body2" style={{ marginTop: '10px' }}>
                  {error}
                </Typography>
              )}
            </Box>
          </Modal>
          <Modal open={openUpdateModal} onClose={handleCloseUpdateModal} aria-labelledby="update-modal-title" aria-describedby="update-modal-description">
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
            }}>
              <Typography id="update-modal-title" variant="h6" component="h2">
                Update Transaction Fee
              </Typography>
              <TextField
                label="Transaction Type"
                name="transaction_type"
                value={updateFormData.transaction_type}
                onChange={handleUpdateFormChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Percentage"
                name="percentage"
                value={updateFormData.percentage}
                onChange={handleUpdateFormChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Agent Percentage"
                name="agent_percentage"
                value={updateFormData.agent_percentage}
                onChange={handleUpdateFormChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Transaction Amount Limit"
                name="transaction_amount_limit"
                value={updateFormData.transaction_amount_limit}
                onChange={handleUpdateFormChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Near Limit Range"
                name="near_limit_rang"
                value={updateFormData.near_limit_rang}
                onChange={handleUpdateFormChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Daily Limit"
                name="Daily_Limit"
                value={updateFormData.Daily_Limit}
                onChange={handleUpdateFormChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Monthly Limit"
                name="monthly_limit"
                value={updateFormData.monthly_limit}
                onChange={handleUpdateFormChange}
                fullWidth
                margin="normal"
              />
              <Button variant="contained" color="primary" onClick={handleUpdateTransactionFee} startIcon={<AddCircleOutlineIcon />} style={{ marginTop: '10px' }}>
                Update Transaction Fee
              </Button>
              {error && (
                <Typography color="error" variant="body2" style={{ marginTop: '10px' }}>
                  {error}
                </Typography>
              )}
            </Box>
          </Modal>
        </>
      )}
    </div>
  );
}

export default TransactionFees;
