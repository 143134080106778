import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import ComplienceComponent from '../Components/ComplienceComponent'

function Complience() {
  return (
    <div>
      
      <SideNav/>
      <ComplienceComponent/>
    </div>
  )
}

export default Complience
