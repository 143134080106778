import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import axios from 'axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  overflow: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const CreateBranch = () => {
  const [open, setOpen] = useState(false);
  const [branches, setBranches] = useState([]);
  const [branchFormOpen, setBranchFormOpen] = useState(false);
  const [formData, setFormData] = useState({
    Branch_id: '',  // Update to match API requirement
    country: '',
    city: '',
    location: '',
    branch_name: '',
    address: '',
    type: '',
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchBranches();
  }, []);

  const fetchBranches = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('admin_token');
      if (!token) {
        setError('No authentication token found. Please log in.');
        setLoading(false);
        return;
      }
      const response = await axios.get('https://api.nkolozi.com/api/Admin/branchs', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.data && Array.isArray(response.data.data)) {
        setBranches(response.data.data);
        console.log(response.data.data);
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Fetch branches error:', error.response?.data || error.message);
      setError(`Failed to fetch branches: ${error.response?.data?.message || error.message || 'An unexpected error occurred'}`);
    }
    setLoading(false);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setError('');
  };

  const handleBranchFormOpen = () => setBranchFormOpen(true);
  const handleBranchFormClose = () => {
    setBranchFormOpen(false);
    setError('');
    setFormData({
      branch_id: '',  
      country: '',
      city: '',
      location: '',
      branch_name: '',
      address: '',
      type: '',
    });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateFormData = () => {
    const { branch_id, country, city, location, branch_name, address, type } = formData;
    if (!branch_id && formData.id) return 'Branch ID is required for updating a branch.';
    console.log('Branch Id', branch_id)
    if (!country || !city || !location || !branch_name || !address || !type) return 'All fields are required.';
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    // Make sure formData has the correct data
    console.log('Submitting Data:', formData);

    const validationError = validateFormData();
    if (validationError) {
      setError(validationError);
      setLoading(false);
      return;
    }

    const apiUrl = formData.branch_id
      ? 'https://api.nkolozi.com/api/Admin/update-branch'
      : 'https://api.nkolozi.com/api/Admin/add-branch';

    try {
      const token = localStorage.getItem('admin_token');
      if (!token) {
        setError('No authentication token found. Please log in.');
        setLoading(false);
        return;
      }

      const response = await axios.post(apiUrl, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        handleBranchFormClose();
        fetchBranches();
      } else {
        setError('Failed to process branch operation');
      }
    } catch (error) {
      console.error('Submit branch error:', error.response?.data || error.message);
      setError(`Error: ${error.response?.data?.message || error.message || 'An unexpected error occurred'}`);
    }
    setLoading(false);
  };

  const handleDelete = async (branch_id) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('admin_token');
      if (!token) {
        setError('No authentication token found. Please log in.');
        setLoading(false);
        return;
      }

      const response = await axios.post('https://api.nkolozi.com/api/Admin/delete-branch', { branch_id }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        fetchBranches();
      } else {
        setError('Failed to delete branch');
      }
    } catch (error) {
      console.error('Delete branch error:', error.response?.data || error.message);
      setError(`Error: ${error.response?.data?.message || error.message || 'An unexpected error occurred'}`);
    }
    setLoading(false);
  };

  const handleUpdateClick = (branch) => {
    setFormData({
      branch_id: branch.branch_id,
      country: branch.country,
      city: branch.city,
      location: branch.location,
      branch_name: branch.branch_name,
      address: branch.address,
      type: branch.type,
    });
    console.log('Formu Dhata',formData)
    handleBranchFormOpen();
    

    
  };
  
  
  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<AddCircleOutlineIcon />}>
        Manage Branches
      </Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
            Branches
          </Typography>
          <Button variant="contained" color="primary" onClick={handleBranchFormOpen} startIcon={<AddCircleOutlineIcon />}>
            Add Branch
          </Button>
          {error && (
            <Typography color="error" variant="body2" style={{ marginTop: '10px' }}>
              {error}
            </Typography>
          )}
          {loading ? (
            <CircularProgress style={{ marginTop: '20px' }} />
          ) : (
            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Country</TableCell>
                    <TableCell>City</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Branch Name</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(branches) && branches.map((branch) => (
                    <TableRow key={branch.id}>
                      <TableCell>{branch.id}</TableCell>
                      <TableCell>{branch.country}</TableCell>
                      <TableCell>{branch.city}</TableCell>
                      <TableCell>{branch.location}</TableCell>
                      <TableCell>{branch.branch_name}</TableCell>
                      <TableCell>{branch.address}</TableCell>
                      <TableCell>{branch.type}</TableCell>
                      <TableCell>
                        <Button variant="contained" color="primary" onClick={() => handleUpdateClick(branch)} style={{ marginRight: '10px' }}>
                          Update
                        </Button>
                        <Button variant="contained" color="secondary" onClick={() => handleDelete(branch.id)}>
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Modal>
      <Modal open={branchFormOpen} onClose={handleBranchFormClose} aria-labelledby="branch-form-modal-title" aria-describedby="branch-form-modal-description">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="branch-form-modal-title" variant="h6" component="h2">
            {formData.Branch_id ? 'Update Branch' : 'Add Branch'}
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              name="country"
              label="Country"
              fullWidth
              margin="normal"
              value={formData.country}
              onChange={handleChange}
            />
            <TextField
              name="city"
              label="City"
              fullWidth
              margin="normal"
              value={formData.city}
              onChange={handleChange}
            />
            <TextField
              name="location"
              label="Location"
              fullWidth
              margin="normal"
              value={formData.location}
              onChange={handleChange}
            />
            <TextField
              name="branch_name"
              label="Branch Name"
              fullWidth
              margin="normal"
              value={formData.branch_name}
              onChange={handleChange}
            />
            <TextField
              name="address"
              label="Address"
              fullWidth
              margin="normal"
              value={formData.address}
              onChange={handleChange}
            />
            <TextField
              name="type"
              label="Type"
              fullWidth
              margin="normal"
              value={formData.type}
              onChange={handleChange}
            />
            {error && (
              <Typography color="error" variant="body2" style={{ marginTop: '10px' }}>
                {error}
              </Typography>
            )}
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button variant="contained" color="primary" type="submit" disabled={loading}>
                {loading ? <CircularProgress size={24} /> : formData.Branch_id ? 'Update Branch' : 'Add Branch'}
              </Button>
              <Button variant="contained" onClick={handleBranchFormClose}>
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default CreateBranch;
