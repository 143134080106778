import React from 'react'
import SideNav from '../Components/SideNav'
import TransactionsComp from '../Components/TransactionsComp'

function Transactions() {
  return (
    <>
   <SideNav/>
   <TransactionsComp/>
   </>
  )
}

export default Transactions
