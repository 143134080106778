import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import AgentsComp from '../Components/AgentsComp'

function Agents() {
  return (
    <>
    <SideNav/>
    <AgentsComp/>
    </>
  )
}

export default Agents
