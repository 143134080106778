import React from 'react'
import LimitComp from '../Components/LimitComp'
import SideNav from '../Components/SideNav'

function Limit() {
  return (
    <div>
    <SideNav/>
      <LimitComp/>
    </div>
  )
}

export default Limit
