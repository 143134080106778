import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'

import HighRiskTransComponent from '../Components/HighRiskTransComponent'

function HighRiskTrans() {
  return (
    <div>
      
      <SideNav/>
      <HighRiskTransComponent/>
    </div>
  )
}

export default HighRiskTrans
