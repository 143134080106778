import React from 'react'
import FloatsComp from '../Components/FloatsComp'
import SideNav from '../Components/SideNav'

function Floats() {
  return (
    <div>
    <SideNav/>
    <FloatsComp/>  
    </div>
  )
}

export default Floats
