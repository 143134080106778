import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import RiskComponent from '../Components/RiskComponent'

function Risk() {
  return (
    <div>
  
      <SideNav />
      <RiskComponent />
    </div>
  )
}

export default Risk
