import React from 'react'
import ViewInnerComp from '../Components/ViewInnerComp'

function ViewInner() {
  return (
    <div>
      <ViewInnerComp/>
    </div>
  )
}

export default ViewInner
