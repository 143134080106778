import React from 'react'
import SideNav from '../Components/SideNav'
import Main from '../Components/Main'

function Dashboard() {
  return (
  <div>
      
       <SideNav/>
       <Main/>
  </div>
  )
}

export default Dashboard
