import React, { useState, useEffect } from 'react';
import { Button, Modal, Box, Typography, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import axios from 'axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  overflow: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const ManageBankAccounts = () => {
  const [openManageModal, setOpenManageModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [formData, setFormData] = useState({
    swift_code: '',
    bank_name: '',
    account_number: '',
    account_type: '',
    currency: '',
  });
  const [error, setError] = useState('');

  useEffect(() => {
    fetchBankAccounts();
  }, []);

  const fetchBankAccounts = async () => {
    try {
      const response = await axios.get('https://api.nkolozi.com/api/Admin/banks-accounts ', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
        },
      });
      setBankAccounts(response.data.data); 
      console.log(response)
    } catch (error) {
      console.error('Failed to fetch bank accounts', error);
      setError('Failed to fetch bank accounts');
    }
  };

  const handleOpenManageModal = () => setOpenManageModal(true);
  const handleCloseManageModal = () => setOpenManageModal(false);

  const handleOpenAddModal = () => {
    setFormData({
      swift_code: '',
      bank_name: '',
      account_number: '',
      account_type: '',
      currency: '',
    });
    setOpenAddModal(true);
  };
  const handleCloseAddModal = () => setOpenAddModal(false);

  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddBankAccount = async () => {
    try {
      const response = await axios.post(
        'https://api.nkolozi.com/api/Admin/add-bank-account',
        {
          swift_code: formData.swift_code,
          bank_name: formData.bank_name,
          account_number: formData.account_number,
          account_type: formData.account_type,
          currency: formData.currency,
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Bank account added successfully', response.data);
      handleCloseAddModal();
      fetchBankAccounts();
    } catch (error) {
      console.error('Failed to add bank account', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
        setError(`Error: ${error.response.data.message || 'An unexpected error occurred'}`);
      } else {
        setError(`Error: ${error.message || 'An unexpected error occurred'}`);
      }
    }
  };

  const handleUpdate = async (bankAccountId) => {
    try {
      const response = await axios.post(
        'https://api.nkolozi.com/api/Admin/update-bank-account',
        {
          ...formData,
          Nkolozi_Bank_id: bankAccountId,
          id: localStorage.getItem('user_id'), // Ensure the id field is included in the request payload
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Bank account updated successfully', response.data);
      handleCloseAddModal();
      fetchBankAccounts();
    } catch (error) {
      console.error('Failed to update bank account', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
        setError(`Error: ${error.response.data.message || 'An unexpected error occurred'}`);
      } else {
        setError(`Error: ${error.message || 'An unexpected error occurred'}`);
      }
    }
  };

  const handleDelete = async (bankAccountId) => {
    try {
      const userId = localStorage.getItem('user_id'); // Fetch user ID from local storage
      const response = await axios.post(
        'https://api.nkolozi.com/api/Admin/delete-bank-account',
        {
          Nkolozi_Bank_id: bankAccountId,
          id: userId, // Pass user ID along with bank account ID
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Bank account deleted successfully', response.data);
      fetchBankAccounts();
    } catch (error) {
      console.error('Failed to delete bank account', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
        setError(`Error: ${error.response.data.message || 'An unexpected error occurred'}`);
      } else {
        setError(`Error: ${error.message || 'An unexpected error occurred'}`);
      }
    }
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleOpenManageModal}>
        Manage Bank Accounts
      </Button>
      <Modal open={openManageModal} onClose={handleCloseManageModal} aria-labelledby="modal-title" aria-describedby="modal-description">
        <Box sx={style}>
          <Typography id="modal-title" variant="h2">Manage Bank Accounts</Typography>
          {error && <Typography color="error">{error}</Typography>}
          <Button variant="contained" color="primary" onClick={handleOpenAddModal} startIcon={<AddCircleOutlineIcon />}>
            Add Bank Account
          </Button>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Bank Name</TableCell>
                  <TableCell>Account Number</TableCell>
                  <TableCell>Account Type</TableCell>
                  <TableCell>Currency</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bankAccounts.map((bankAccount) => (
                  <TableRow key={bankAccount.id}>
                    <TableCell>{bankAccount.bank_name}</TableCell>
                    <TableCell>{bankAccount.account_number}</TableCell>
                    <TableCell>{bankAccount.account_type}</TableCell>
                    <TableCell>{bankAccount.currency}</TableCell>
                    <TableCell>
                      <Button variant="contained" color="secondary" onClick={() => handleUpdate(bankAccount.id)}>Edit</Button>
                      <Button variant="contained" color="error" onClick={() => handleDelete(bankAccount.id)}>Delete</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
      <Modal open={openAddModal} onClose={handleCloseAddModal} aria-labelledby="modal-title" aria-describedby="modal-description">
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">Add Bank Account</Typography>
          {error && <Typography color="error">{error}</Typography>}
          <form>
            <TextField label="SWIFT Code" name="swift_code" value={formData.swift_code} onChange={handleFormChange} fullWidth margin="normal" />
            <TextField label="Bank Name" name="bank_name" value={formData.bank_name} onChange={handleFormChange} fullWidth margin="normal" />
            <TextField label="Account Number" name="account_number" value={formData.account_number} onChange={handleFormChange} fullWidth margin="normal" />
            <TextField label="Account Type" name="account_type" value={formData.account_type} onChange={handleFormChange} fullWidth margin="normal" />
            <TextField label="Currency" name="currency" value={formData.currency} onChange={handleFormChange} fullWidth margin="normal" />
            <Button variant="contained" color="primary" onClick={handleAddBankAccount} sx={{ mt: 2 }}>Add</Button>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default ManageBankAccounts;
