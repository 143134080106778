import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

// Define styles
const style = {
  marginLeft: '20%',
  marginTop: '4%',
  width: '80%', // Reduce the width to create room for scrolling
  overflow: 'auto', // Ensure container is scrollable
};

const tableContainerStyle = {
  maxHeight: '70vh',
  overflowX: 'auto', // Makes the table scrollable horizontally
};

const exportButtonsStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '1em',
};

function TransHistoryComp() {
  const [histories, setHistories] = useState([]);
  const [filteredHistories, setFilteredHistories] = useState([]); // For filtered data
  const [error, setError] = useState('');
  const [clients, setClients] = useState({});
  const [agents, setAgents] = useState({});
  const [currencies, setCurrencies] = useState({});
  const [beneficiaries, setBeneficiaries] = useState({}); 
  const [searchTerm, setSearchTerm] = useState(''); // Search term state

  // Fetch transaction histories, clients, agents, and currencies
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('admin_token');

        // Fetch transaction histories
        const historyResponse = await axios.get(
          'https://api.nkolozi.com/api/Admin/histories',
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        // Fetch clients
        const clientsResponse = await axios.get(
          'https://api.nkolozi.com/api/Admin/clients',
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        // Fetch agents
        const agentsResponse = await axios.get(
          'https://api.nkolozi.com/api/Admin/agents',
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        // Fetch currencies
        const currenciesResponse = await axios.get(
          'https://api.nkolozi.com/api/Admin/currencies',
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

         // Fetch beneficiaries
         const beneficiariesResponse = await axios.get(
          'https://api.nkolozi.com/api/Admin/get-beneficiaries',
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        // Set histories to state
        setHistories(historyResponse.data.history);
        setFilteredHistories(historyResponse.data.history); // Initialize filtered data


        // Set histories, clients, and currencies to state
        setHistories(historyResponse.data.history);
        setFilteredHistories(historyResponse.data.history); // Initialize filtered data

        // Map client ID to their full name for easier lookup
        const clientsMap = {};
        clientsResponse.data.data.forEach((client) => {
          clientsMap[client.id] = `${client.name} ${client.surname}`;
        });
        setClients(clientsMap);

        // Map agent ID to their full name for easier lookup
        const agentsMap = {};
        agentsResponse.data.data.forEach((agent) => {
          agentsMap[agent.id] = `${agent.name} ${agent.surname}`;
        });
        setAgents(agentsMap);

        // Map currency ID to currency name for easier lookup
        const currenciesMap = {};
        currenciesResponse.data.data.forEach((currency) => {
          currenciesMap[currency.id] = currency.name;
        });
        setCurrencies(currenciesMap);


        // Map beneficiary ID to full name for easier lookup
        const beneficiariesMap = {};
        beneficiariesResponse.data.beneficiaries.forEach((beneficiary) => {
          beneficiariesMap[beneficiary.id] = beneficiary.full_name;
        });
        setBeneficiaries(beneficiariesMap);

        
      } catch (error) {
        console.error('Failed to fetch data:', error);
        if (error.response) {
          setError(
            `Error: ${error.response.data.message || 'An unexpected error occurred'}`
          );
        } else {
          setError(`Error: ${error.message || 'An unexpected error occurred'}`);
        }
      }
    };

    

    
    fetchData();
  }, []);

  // Helper function to format date
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Search function to filter table based on input
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = histories.filter((history) => {
      const clientName = clients[history.user_id]?.toLowerCase() || '';
      return clientName.includes(value);
    });
    setFilteredHistories(filtered);
  };

  // Function to export table to PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        [
          'ID',
          'User Name',
          'Beneficiary ID',
          'Agent Name',
          'Client Name',
          'Currency',
          'Amount',
          'Type',
          'Created At',
          'Updated At',
        ],
      ],
      body: filteredHistories.map((history) => [
        history.id,
        clients[history.user_id] || 'Unknown User',
        history.beneficiary_id,
        agents[history.agent_id] || 'N/A',
        clients[history.client_id] || 'Unknown Client',
        currencies[history.currency_id] || 'Unknown Currency',
        history.amount,
        history.type,
        formatDate(history.created_at),
        formatDate(history.updated_at),
      ]),
    });
    doc.save('transaction_history.pdf');
  };

  // Function to export table to Excel
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      filteredHistories.map((history) => ({
        ID: history.id,
        'User Name': clients[history.user_id] || 'Unknown User',
        'Beneficiary ID': history.beneficiary_id,
        'Agent Name': agents[history.agent_id] || 'N/A',
        'Client Name': clients[history.client_id] || 'Unknown Client',
        Currency: currencies[history.currency_id] || 'Unknown Currency',
        Amount: history.amount,
        Type: history.type,
        'Created At': formatDate(history.created_at),
        'Updated At': formatDate(history.updated_at),
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Transaction History');
    XLSX.writeFile(workbook, 'transaction_history.xlsx');
  };

  return (
    <div style={style}>
      <Typography variant="h4" gutterBottom>
        Transaction History
      </Typography>
      {error && <Typography color="error">{error}</Typography>}

      {/* Search bar */}
      <TextField
        label="Search by Name or Surname"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearch}
        fullWidth
        style={{ marginBottom: '20px' }}
      />

      {/* Export buttons */}
      <div style={exportButtonsStyle}>
        <Button
          variant="contained"
          color="primary"
          onClick={exportToPDF}
          style={{ marginRight: '10px' }}
        >
          Export to PDF
        </Button>
        <Button variant="contained" color="secondary" onClick={exportToExcel}>
          Export to Excel
        </Button>
      </div>

      <div style={{ overflowX: 'auto' }}>
        <TableContainer component={Paper} style={tableContainerStyle}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
               
               
                <TableCell>Beneficiary ID</TableCell>
                <TableCell>Agent Name</TableCell>
                <TableCell>Client Name</TableCell>
                <TableCell>Currency</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Updated At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredHistories.map((history) => (
                <TableRow key={history.id}>
                 
                  
                  <TableCell>{ beneficiaries[history.beneficiary_id] || 'Unknown Beneficiary'}</TableCell>
                  <TableCell>{agents[history.agent_id] || 'N/A'}</TableCell>
                  <TableCell>{clients[history.client_id] || 'Unknown Client'}</TableCell>
                  <TableCell>{currencies[history.currency_id] || 'Unknown Currency'}</TableCell>
                  <TableCell>{history.amount}</TableCell>
                  <TableCell>{history.type}</TableCell>
                  <TableCell>{formatDate(history.created_at)}</TableCell>
                  <TableCell>{formatDate(history.updated_at)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default TransHistoryComp;
