import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import ExchangeRatesComp from '../Components/ExchangeRatesComp'

function ExchangeRates() {
  return (
    <>
   
      <SideNav/>
      <ExchangeRatesComp/>
    </>
  )
}

export default ExchangeRates
