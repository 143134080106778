import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import TransHistoryComp from '../Components/TransHistoryComp'

function TransHistory() {
  return (
    <div>
      <Nav/>
      <SideNav/>
      <TransHistoryComp/>
    </div>
  )
}

export default TransHistory
