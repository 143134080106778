import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import RatesComponent from '../Components/RatesComponent'

function Rates() {
  return (
    <div>

    <SideNav />
    <RatesComponent />
      
    </div>
  )
}

export default Rates
