import React from 'react';
import { Container, Typography, Grid, TextField, Button, Paper, MenuItem } from '@mui/material';
import { Box } from '@mui/system';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

function ExchangeRatesComp() {
  return (
    <Container maxWidth="md" style={{ marginTop: '50px' }}>
      <Paper elevation={3} style={{ padding: '60px' }}>
        <Box display="flex" alignItems="center" justifyContent="center" mb={3}>
          <SwapHorizIcon style={{ marginRight: '20px' }} />
          <Typography variant="h4" align="center">
            Exchange Rates
          </Typography>
        </Box>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                label="Originating Country"
                name="originatingCountry"
                variant="outlined"
                required
              >
                <MenuItem value="Botswana">Botswana</MenuItem>
                <MenuItem value="Zimbabwe">Zimbabwe</MenuItem>
                <MenuItem value="South Africa">South Africa</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                label="Destination Country"
                name="destinationCountry"
                variant="outlined"
                required
              >
                <MenuItem value="Botswana">Botswana</MenuItem>
                <MenuItem value="Zimbabwe">Zimbabwe</MenuItem>
                <MenuItem value="South Africa">South Africa</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Exchange Rate (Origin to Destination)"
                name="exchangeRate"
                type="number"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Reverse Exchange Rate (Destination to Origin)"
                name="reverseExchangeRate"
                type="number"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Effective Date"
                name="effectiveDate"
                type="date"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}

export default ExchangeRatesComp;
