import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Table, Dropdown, DropdownButton, Spinner, InputGroup, FormControl } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { countries } from 'countries-list';

function EmployeesComponent() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [newEmployee, setNewEmployee] = useState({
    name: '',
    surname: '',
    lastname: '',
    country: '',
    dob: '',
    gender: '',
    national_id: '',
    phone1: '',
    email: '',
    role_id: '',
    user_type: '',
    phone2: '',
    city: '',
    location: '',
    street_line: '',
    house_number: '',
    id_photo: null,
    user_photo: null,
    p_o_r_image: null,
  });
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleShowAddModal = () => setShowAddModal(true);
  const handleCloseAddModal = () => {
    setShowAddModal(false);
    resetForm();
  };

  const handleShowDetailsModal = (employee) => {
    setSelectedEmployee(employee);
    setShowDetailsModal(true);
  };
  const handleCloseDetailsModal = () => setShowDetailsModal(false);

  const resetForm = () => {
    setNewEmployee({
      name: '',
      surname: '',
      lastname: '',
      country: '',
      dob: '',
      gender: '',
      national_id: '',
      phone1: '',
      email: '',
      role_id: '',
      user_type: '',
      phone2: '',
      city: '',
      location: '',
      street_line: '',
      house_number: '',
      id_photo: null,
      user_photo: null,
      p_o_r_image: null,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewEmployee({ ...newEmployee, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setNewEmployee({ ...newEmployee, [name]: files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitting(true);
    try {
      const token = localStorage.getItem('admin_token');
      if (!token) {
        throw new Error('User not authenticated');
      }

      const formData = new FormData();
      Object.keys(newEmployee).forEach(key => {
        if (newEmployee[key]) {
          formData.append(key, newEmployee[key]);
        }
      });

      const response = await fetch('https://api.nkolozi.com/api/Admin/register-employee', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData,
      });

      if (!response.ok) {
        const errorResponse = await response.text();
        console.error('Server response:', errorResponse);
        throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorResponse}`);
      }

      const data = await response.json();
      console.log('New employee added:', data);

      handleCloseAddModal();
      alert('New employee added successfully!');
      fetchEmployees();
    } catch (error) {
      console.error('Error adding new employee:', error.message);
      alert(`Error adding new employee. ${error.message}`);
    } finally {
      setSubmitting(false);
    }
  };

  const fetchEmployees = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('admin_token');
      if (!token) {
        throw new Error('User not authenticated');
      }

      const response = await fetch('https://api.nkolozi.com/api/Admin/employees', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        const errorResponse = await response.text();
        console.error('Server response:', errorResponse);
        throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorResponse}`);
      }

      const data = await response.json();
      setEmployees(data.data); // Assuming the employees array is within the 'data' property based on your API response structure
      console.log('Fetched employees:', data.data);
    } catch (error) {
      console.error('Error fetching employees:', error.message);
      alert(`Error fetching employees. ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleAction = async (action, id) => {
    const token = localStorage.getItem('admin_token');
    if (!token) {
      alert('User not authenticated');
      return;
    }

    const actionUrls = {
      block: `https://api.nkolozi.com/api/Admin/block-user/${id}`,
      unblock: `https://api.nkolozi.com/api/Admin/un-block-user/${id}`,
      activate: `https://api.nkolozi.com/api/Admin/activate-client/${id}`,
    };

    setLoading(true);
    try {
      const response = await fetch(actionUrls[action], {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        const errorResponse = await response.text();
        console.error('Server response:', errorResponse);
        throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorResponse}`);
      }

      alert(`${action.charAt(0).toUpperCase() + action.slice(1)} user successfully!`);
      fetchEmployees();
    } catch (error) {
      console.error(`Error ${action} user:`, error.message);
      alert(`Error ${action} user. ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const filteredEmployees = employees.filter(employee =>
    employee.phone1.includes(search)
  );

  useEffect(() => {
    fetchEmployees();
  }, []);

  return (
    <div style={{ marginLeft: '20%', marginTop: '4%', overflowY: 'auto' }}>
      <h1>Employees</h1>

      <Button variant="primary" onClick={handleShowAddModal}>
        Add Employee
      </Button>

      <InputGroup className="mb-3" style={{ marginTop: '20px', width: '50%' }}>
        <FormControl
          placeholder="Search by phone"
          aria-label="Search by phone"
          aria-describedby="basic-addon2"
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      </InputGroup>

      <Modal show={showAddModal} onHide={handleCloseAddModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={newEmployee.name}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Middle Name</Form.Label>
              <Form.Control
                type="text"
                name="lastname"
                value={newEmployee.lastname}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Surname</Form.Label>
              <Form.Control
                type="text"
                name="surname"
                value={newEmployee.surname}
                onChange={handleChange}
                required
              />
            </Form.Group>

           

            <Form.Group className="mb-3">
            <Form.Label>Country</Form.Label>
            <Form.Control
                as="select"
                name="country"
                value={newEmployee.country}
                onChange={handleChange}
                required
            >
                <option value="" disabled>Select Country</option>
                {Object.keys(countries).map((countryCode) => (
                    <option key={countryCode} value={countryCode}>
                        {countries[countryCode].name}
                    </option>
                ))}
            </Form.Control>
        </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control
                type="date"
                name="dob"
                value={newEmployee.dob}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Gender</Form.Label>
              <Form.Control
                as="select"
                name="gender"
                value={newEmployee.gender}
                onChange={handleChange}
                required
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>National ID</Form.Label>
              <Form.Control
                type="text"
                name="national_id"
                value={newEmployee.national_id}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phone1"
                value={newEmployee.phone1}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={newEmployee.email}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Role ID</Form.Label>
              <Form.Control
                type="text"
                name="role_id"
                value={newEmployee.role_id}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
    <Form.Label>User Type</Form.Label>
    <Form.Control
        as="select"
        name="user_type"
        value={newEmployee.user_type}
        onChange={handleChange}
        required
    >
        <option value="" disabled>Select User Type</option>
        <option value="admin">admin</option>
        <option value="agent">agent</option>
    </Form.Control>
</Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Phone 2</Form.Label>
              <Form.Control
                type="text"
                name="phone2"
                value={newEmployee.phone2}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                value={newEmployee.city}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                name="location"
                value={newEmployee.location}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Street Line</Form.Label>
              <Form.Control
                type="text"
                name="street_line"
                value={newEmployee.street_line}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>House Number</Form.Label>
              <Form.Control
                type="text"
                name="house_number"
                value={newEmployee.house_number}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>ID Photo</Form.Label>
              <Form.Control
                type="file"
                name="id_photo"
                onChange={handleFileChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>User Photo</Form.Label>
              <Form.Control
                type="file"
                name="user_photo"
                onChange={handleFileChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Proof of Residence Image</Form.Label>
              <Form.Control
                type="file"
                name="p_o_r_image"
                onChange={handleFileChange}
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              {submitting ? <Spinner animation="border" size="sm" /> : 'Submit'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {loading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Surname</th>
              <th>Status</th>
              <th>Country</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredEmployees.map((employee, index) => (
              <tr key={index}>
                <td style={{color:"blue"}} onClick={() => handleShowDetailsModal(employee)}>{employee.name}</td>
                <td onClick={() => handleShowDetailsModal(employee)}>{employee.surname}</td>
                <td onClick={() => handleShowDetailsModal(employee)}>{employee.status}</td>
                <td onClick={() => handleShowDetailsModal(employee)}>{employee.country}</td>
                <td>
                  <DropdownButton id="dropdown-basic-button" title="Actions" variant="secondary">
                    <Dropdown.Item onClick={(e) => { e.stopPropagation(); handleAction('block', employee.id); }}>Block</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => { e.stopPropagation(); handleAction('unblock', employee.id); }}>Unblock</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => { e.stopPropagation(); handleAction('activate', employee.id); }}>Activate</Dropdown.Item>
                  </DropdownButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <Modal show={showDetailsModal} onHide={handleCloseDetailsModal}>
        <Modal.Header closeButton>
          <Modal.Title>Employee Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedEmployee && (
            <div>
              <p><strong>Name:</strong> {selectedEmployee.name}</p>
              <p><strong>Surname:</strong> {selectedEmployee.surname}</p>
              <p><strong>Lastname:</strong> {selectedEmployee.lastname}</p>
              <p><strong>Country:</strong> {selectedEmployee.country}</p>
              <p><strong>Date of Birth:</strong> {selectedEmployee.dob}</p>
              <p><strong>Gender:</strong> {selectedEmployee.gender}</p>
              <p><strong>National ID:</strong> {selectedEmployee.national_id}</p>
              <p><strong>Phone 1:</strong> {selectedEmployee.phone1}</p>
              <p><strong>Email:</strong> {selectedEmployee.email}</p>
              <p><strong>Role ID:</strong> {selectedEmployee.role_id}</p>
              <p><strong>User Type:</strong> {selectedEmployee.user_type}</p>
              <p><strong>Phone 2:</strong> {selectedEmployee.phone2}</p>
              <p><strong>City:</strong> {selectedEmployee.city}</p>
              <p><strong>Location:</strong> {selectedEmployee.location}</p>
              <p><strong>Street Line:</strong> {selectedEmployee.street_line}</p>
              <p><strong>House Number:</strong> {selectedEmployee.house_number}</p>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default EmployeesComponent;
