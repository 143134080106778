import React from 'react'
import SanctionedComp from '../Components/SanctionedComp'
import SideNav from '../Components/SideNav'
import Nav from '../Components/Nav'

const SanctionedList = () => {
  return (
    <div>
    <Nav/>
    <SideNav/>
<SanctionedComp/>

    </div>
  )
}

export default SanctionedList