import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, TextField, Button, Paper, MenuItem } from '@mui/material';
import { Box } from '@mui/system';
import SecurityIcon from '@mui/icons-material/Security';
import axios from 'axios';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    marginLeft:'15%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#root');

function RiskComponent() {
  const [riskParameters, setRiskParameters] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    risk_category: '',
    description: '',
    trigger: '',
    severity_level: '',
    threshold: '',
  });

  useEffect(() => {
    const config = {
      headers: {
        'Authorization': 'Bearer YOUR_ACCESS_TOKEN' // Replace with your token
      }
    };

    axios.get('https://api.nkolozi.com/api/Admin/risk_parameters', config)
      .then(response => {
        console.log('API Response:', response.data);
        setRiskParameters(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the risk parameters!', error);
      });
  }, []);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const config = {
      headers: {
        'Authorization': 'Bearer YOUR_ACCESS_TOKEN', // Replace with your token
        'Content-Type': 'application/json'
      }
    };

    axios.post('https://api.nkolozi.com/api/Admin/add_risk_parameter', formData, config)
      .then(response => {
        console.log('Added risk parameter:', response.data);
        setRiskParameters(prevState => [...prevState, response.data]);
        closeModal();
      })
      .catch(error => {
        console.error('There was an error adding the risk parameter!', error);
      });
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '2%', overflow: 'auto', height: '100vh' }}>
      <Paper elevation={3} style={{ padding: '10%' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <Box display="flex" alignItems="center">
            <SecurityIcon fontSize="large" />
            <Typography variant="h4" component="h1" style={{ marginLeft: '10px' }}>
              Risk Parameters
            </Typography>
          </Box>
          <Button variant="contained" color="primary" onClick={openModal}>
            Add Risk Parameters
          </Button>
        </Box>
        <Grid container spacing={3}>
          {riskParameters.map((param, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <TextField
                fullWidth
                label={param.name}
                value={param.value}
                variant="outlined"
                disabled
              />
            </Grid>
          ))}
        </Grid>
        <Box mt={4} display="flex" justifyContent="flex-end">
          <Button variant="contained" color="primary">
            Update Parameters
          </Button>
        </Box>
      </Paper>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Add Risk Parameter"
      >
        <Typography variant="h6" component="h2">Add Risk Parameter</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label="Risk Category"
            name="risk_category"
            value={formData.risk_category}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Trigger"
            name="trigger"
            value={formData.trigger}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Severity Level"
            name="severity_level"
            value={formData.severity_level}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Threshold"
            name="threshold"
            value={formData.threshold}
            onChange={handleChange}
            required
          />
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button variant="contained" color="secondary" onClick={closeModal} style={{ marginRight: '10px' }}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Add Parameter
            </Button>
          </Box>
        </form>
      </Modal>
    </Container>
  );
}

export default RiskComponent;
