import React from 'react';
import { Link } from 'react-router-dom';

function SideNav() {
  return (
    <div id="layoutSidenav">
      <div
        id="layoutSidenav_nav"
        style={{
          position: 'fixed',
          top: 58,
          left: 0,
          height: '100vh',
          width: '250px',
          overflow: 'auto',
          background: 'linear-gradient(135deg, #003366, #3366cc)', // Updated background
        }}
      >
        <nav className="sb-sidenav accordion " id="sidenavAccordion">
          <div className="sb-sidenav-menu">
            <div className="nav">

              <Link to="/dashboard" className="nav-link">
                <div style={{ color: 'white' }} className="sb-nav-link-icon"><i className="fas fa-tachometer-alt" /></div>
              <div style={{ color: 'white' }}>  Dashboard</div>
              </Link>

              <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts">
                <div  style={{ color: 'white' }} className="sb-nav-link-icon"><i className="fas fa-columns" /></div>
                <div style={{ color: 'white' }}>Transactions</div>
                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down" /></div>
              </a>
              <div className="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                <nav className="sb-sidenav-menu-nested nav">
                  <Link to="/transactions" className="nav-link" style={{ color: 'white' }}>Transactions</Link>
                  <Link to="/initiated" className="nav-link"style={{ color: 'white' }}>Initiated Transactions</Link>
                  {/* <Link to="/transStatus" className="nav-link" style={{ color: 'white' }}>Transaction Fees</Link> */}
                  <Link to="/trans-hist" className="nav-link" style={{ color: 'white' }}>Transaction History</Link>
                  {/* <Link to="/highRisk" className="nav-link" style={{ color: 'white' }}>High risk transactions</Link>
                  <Link to="/suspended" className="nav-link" style={{ color: 'white' }}>Suspended transactions</Link>
                  <Link to="/deposits" className="nav-link" style={{ color: 'white' }}>Deposits</Link> */}
                </nav>
              </div>

              <Link to="/clients" className="nav-link">
                <div className="sb-nav-link-icon"><i className="fas fa-user-friends" style={{ color: 'white' }}/></div>
                <div style={{ color: 'white' }}>Clients</div>
              </Link>

              <Link to="/agents" className="nav-link">
                <div className="sb-nav-link-icon"><i className="fas fa-user-tie" style={{ color: 'white' }} /></div>
               <div style={{ color: 'white' }}> Agents </div>
              </Link>

              <Link to="/employees" className="nav-link">
                <div className="sb-nav-link-icon"><i style={{ color: 'white' }} className="fas fa-id-badge" /></div>
                <div style={{ color: 'white' }}>Employees</div>
              </Link>

              <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
                <div style={{ color: 'white' }} className="sb-nav-link-icon"><i className="fas fa-book-open" /></div>
               <div style={{ color: 'white' }}> System Administration</div>
                <div className="sb-sidenav-collapse-arrow"><i style={{ color: 'white' }} className="fas fa-angle-down" /></div>
              </a>
              <div className="collapse" id="collapsePages" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion">
                <nav className="sb-sidenav-menu-nested nav accordion" id="sidenavAccordionPages">
                  
                  <Link to="/sanction" className="nav-link" style={{ color: 'white' }}>
                    <div className="sb-nav-link-icon" style={{ color: 'white' }}><i className="fas fa-chart-area" style={{ color: 'white' }} /></div>
                   <div style={{ color: 'white' }}> Sanctioned List</div>
                  </Link>

                  <Link to="/rbz" className="nav-link" style={{ color: 'white' }}>
                    <div className="sb-nav-link-icon" style={{ color: 'white' }}><i className="fas fa-university" style={{ color: 'white' }} /></div>
                   <div style={{ color: 'white' }}> RBZ</div>
                  </Link>
                </nav>
              </div>

              

              <Link to="/settings" className="nav-link">
                <div className="sb-nav-link-icon"><i className="fas fa-cogs" style={{ color: 'white' }}/></div>
               <div style={{ color: 'white' }}>Settings</div>
              </Link>

              <Link to="/logout" className="nav-link" >
                <div className="sb-nav-link-icon"><i className="fas fa-sign-out-alt" style={{ color: 'white' }} /></div>
                <div style={{ color: 'white' }}>Logout</div>
              </Link>

            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default SideNav;