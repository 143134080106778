import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Circles } from 'react-loader-spinner';
import './Login.css'; // Import CSS for the Logout component

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    const logoutUser = async () => {
      const token = localStorage.getItem('admin_token'); // Updated to use the correct token

      try {
        const response = await fetch('https://api.nkolozi.com/api/logout', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        const data = await response.json();

        if (response.ok) {
          // Delete the auth token from localStorage and cookies
          localStorage.removeItem('agent_token');
          document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          // Redirect to the login screen
          setTimeout(() => {
            navigate('/');
          }, 2000);
        } else {
          console.error(data.message || 'Something went wrong');
          // Ensure the token is removed even if the logout request fails
          localStorage.removeItem('admin_token');
          document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          navigate('/login');
        }
      } catch (error) {
        console.error('Logout failed:', error);
        // Ensure the token is removed even if there's an error
        localStorage.removeItem('admin_token');
        document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        navigate('/login');
      }
    };

    logoutUser();
  }, [navigate]);

  return (
    <div className="logout-spinner-container">
      <p className="logout-text">Signing out...</p>
      <Circles 
        height="100" 
        width="100" 
        color="#4fa94d" 
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
}

export default Logout;
