import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import SettingsComponent from '../Components/SettingsComponent'

function Settings() {
  return (
    <div>
     
     <SideNav/>
     <SettingsComponent/> 
    </div>
  )
}

export default Settings
