import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import UsersComponenent from '../Components/UsersComponent'

function Users() {
  return (
    <>
      <SideNav/>
      <UsersComponenent/>
    </>
  )
}

export default Users
