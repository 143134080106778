import React from 'react'
import EmployeesComponent from '../Components/EmployeesComponents'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'

function Employees() {
  return (
    <div>
      
      <SideNav/>
      <EmployeesComponent/>
    </div>
  )
}

export default Employees
