import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css'; 
import logo from '../Images/Logoo.png'; 
import backgroundVideo from '../Images/Bank2.mp4';
import axios from 'axios';

function Login() {
  const navigate = useNavigate();
  const [reg_id, setReg_id] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const storedToken = localStorage.getItem('admin_token');
    if (storedToken) {
      navigate('/');
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await axios.post('https://api.nkolozi.com/api/employee_login', { reg_id, password });

      if (response.data && response.data.data) {
        localStorage.setItem('admin_token', response.data.data);
        // Uncomment and adjust the line below if there is a specific user ID you need to store
        // localStorage.setItem('user_id', response.data.user.id);
        navigate('/');
      } else {
        console.error('Unexpected response structure:', response.data);
        setError('Unexpected response structure from the server. Please try again.');
      }

    } catch (error) {
      if (error.response) {
        console.error('Error response:', error.response);
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
        setError(error.response.data.message || 'An error occurred. Please try again.');
      } else if (error.request) {
        console.error('Error request:', error.request);
        setError('No response received from server. Please check your network connection.');
      } else {
        console.error('Error message:', error.message);
        setError('An error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="app">
      <video autoPlay loop muted className="background-video">
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      <div className="login-container">
        <div className="login-box">
          <div className="logo-container">
            <img src={logo} alt="Logo" className="logo" />
          </div>
          <h1 className="login-title">Nkolozi Money Transfer</h1>
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="input-group">
              <label htmlFor="userID">User ID</label>
              <input
                type="text"
                id="userID"
                name="userID"
                value={reg_id}
                onChange={(e) => setReg_id(e.target.value)}
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {error && <p className="error">{error}</p>}
            <button type="submit" className="login-button" disabled={loading}>
              {loading ? 'Signing In...' : 'Sign In'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
