import React from 'react';
import { TextField, Button, Container, Typography, Grid } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Box } from '@mui/system';

function AddComponent() {
  return (
    <Container maxWidth="sm" style={{ marginTop: '100%' }}>
      <Box display="flex" alignItems="center" justifyContent="center" mb={3}>
        <PersonAddIcon style={{ marginRight: '10px' }} />
        <Typography variant="h4" align="center">
          Add Agent
        </Typography>
      </Box>
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Agent ID" 
              name="agentId" 
              variant="outlined" 
              required 
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Name" 
              name="name" 
              variant="outlined" 
              required 
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Surname" 
              name="surname" 
              variant="outlined" 
              required 
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="ID Number" 
              name="idNumber" 
              variant="outlined" 
              required 
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Phone" 
              name="phone" 
              variant="outlined" 
              required 
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Email" 
              name="email" 
              type="email" 
              variant="outlined" 
              required 
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Physical Address" 
              name="physicalAddress" 
              variant="outlined" 
              required 
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Location" 
              name="location" 
              variant="outlined" 
              required 
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Branch" 
              name="branch" 
              variant="outlined" 
              required 
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Password" 
              name="password" 
              type="password" 
              variant="outlined" 
              required 
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Float Limit" 
              name="floatLimit" 
              variant="outlined" 
              required 
            />
          </Grid>
          <Grid item xs={12}>
            <Button 
              fullWidth 
              variant="contained" 
              color="primary" 
              type="submit"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

export default AddComponent;
