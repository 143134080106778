import React, { useState, useEffect } from 'react';
import { Button, Modal, Box, Typography, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import axios from 'axios';

const ManageCurrencies = () => {
  const [openManageModal, setOpenManageModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [formData, setFormData] = useState({
    currency_id: '',
    name: '',
    country: '',
    code: '',
    rate: '',
  });
  const [error, setError] = useState('');

  useEffect(() => {
    fetchCurrencies();
  }, []);

  const fetchCurrencies = async () => {
    const token = localStorage.getItem('admin_token');
    if (!token) {
      setError('Authorization token is missing');
      return;
    }

    try {
      const response = await axios.get('https://api.nkolozi.com/api/Admin/currencies', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setCurrencies(response.data.data || []);
    } catch (error) {
      console.error('Failed to fetch currencies', error);
      setError('Failed to fetch currencies');
    }
  };

  const handleOpenManageModal = () => {
    fetchCurrencies();
    setOpenManageModal(true);
  };

  const handleCloseManageModal = () => setOpenManageModal(false);

  const handleOpenAddModal = () => {
    setFormData({
      currency_id: '',
      name: '',
      country: '',
      code: '',
      rate: '',
    });
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => setOpenAddModal(false);

  const handleOpenEditModal = (currency) => {
    console.log('Selected Currency:', currency); // Debugging line
    setFormData({
      currency_id: currency.id, // Ensure currency_id is set
      name: currency.name,
      country: currency.country,
      code: currency.code,
      rate: currency.rate,
    });
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => setOpenEditModal(false);

  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateFormData = () => {
    const { currency_id, name, country, code, rate } = formData;
    if (!currency_id || !name || !country || !code || !rate) {
      setError('All fields are required.');
      return false;
    }
    return true;
  };

  const handleAddCurrency = async () => {
    if (!validateFormData()) return;

    const token = localStorage.getItem('admin_token');
    if (!token) {
      setError('Authorization token is missing');
      return;
    }

    try {
      const response = await axios.post('https://api.nkolozi.com/api/Admin/add-currency', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      console.log('Currency added successfully', response.data);
      handleCloseAddModal();
      fetchCurrencies();
    } catch (error) {
      console.error('Failed to add currency', error);
      setError(`Error: ${error.response?.data?.message || 'An unexpected error occurred'}`);
    }
  };

  const handleUpdateCurrency = async () => {
    if (!validateFormData()) return;

    const token = localStorage.getItem('admin_token');
    if (!token) {
      setError('Authorization token is missing');
      return;
    }

    try {
      console.log('Updating Currency Data:', formData); // Debugging line
      const response = await axios.post('https://api.nkolozi.com/api/Admin/update-currency', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      console.log('Currency updated successfully', response.data);
      handleCloseEditModal();
      fetchCurrencies();
    } catch (error) {
      console.error('Failed to update currency', error);
      setError(`Error: ${error.response?.data?.message || 'An unexpected error occurred'}`);
    }
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleOpenManageModal} startIcon={<AddCircleOutlineIcon />}>
        Manage Currencies
      </Button>
      <Modal open={openManageModal} onClose={handleCloseManageModal} aria-labelledby="modal-title" aria-describedby="modal-description">
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="modal-title" variant="h6" component="h2">
            Currencies
          </Typography>
          {/* <Button variant="contained" color="primary" onClick={handleOpenAddModal} startIcon={<AddCircleOutlineIcon />} style={{ marginBottom: '10px' }}>
            Add Currency
          </Button> */}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>Code</TableCell>
                  <TableCell>Rate</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currencies.map((currency) => (
                  <TableRow key={currency.id}>
                    <TableCell>{currency.name}</TableCell>
                    <TableCell>{currency.country}</TableCell>
                    <TableCell>{currency.code}</TableCell>
                    <TableCell>{currency.rate}</TableCell>
                    <TableCell>
                      <Button variant="contained" color="primary" onClick={() => handleOpenEditModal(currency)} style={{ marginRight: '10px' }}>
                        Update
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
      <Modal open={openAddModal} onClose={handleCloseAddModal} aria-labelledby="add-modal-title" aria-describedby="add-modal-description">
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="add-modal-title" variant="h6" component="h2">
            Add Currency
          </Typography>
          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Country"
            name="country"
            value={formData.country}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Code"
            name="code"
            value={formData.code}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Rate"
            name="rate"
            value={formData.rate}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={handleAddCurrency} startIcon={<AddCircleOutlineIcon />} style={{ marginTop: '10px' }}>
            Add Currency
          </Button>
          {error && (
            <Typography color="error" variant="body2" style={{ marginTop: '10px' }}>
              {error}
            </Typography>
          )}
        </Box>
      </Modal>
      <Modal open={openEditModal} onClose={handleCloseEditModal} aria-labelledby="edit-modal-title" aria-describedby="edit-modal-description">
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="edit-modal-title" variant="h6" component="h2">
            Edit Currency
          </Typography>
          <TextField
            label="Currency ID"
            name="currency_id"
            value={formData.currency_id}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
            disabled // Assume the currency ID is not editable
          />
          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Country"
            name="country"
            value={formData.country}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Code"
            name="code"
            value={formData.code}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Rate"
            name="rate"
            value={formData.rate}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={handleUpdateCurrency} startIcon={<AddCircleOutlineIcon />} style={{ marginTop: '10px' }}>
            Update Currency
          </Button>
          {error && (
            <Typography color="error" variant="body2" style={{ marginTop: '10px' }}>
              {error}
            </Typography>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ManageCurrencies;
