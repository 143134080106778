import React, { useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import SideNav from './SideNav';
import { Link } from 'react-router-dom';
import MainDash from './MainDash';
import TransactionTraffic from './TransactionTraffic';
import InitiatedComp from './InitiatedComp';
import { Paper, Typography } from '@mui/material';   


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

function Main() {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  

  const transactionData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Total Transactions',
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
      },
      {
        label: 'Total Reversals',
        data: [5, 4, 6, 3, 2, 2, 1],
        fill: false,
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
      },
    ],
  };

  const revenueGrowthData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Revenue Growth',
        data: [3000, 3200, 3500, 3700, 4000, 4200, 4500],
        fill: false,
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
      },
    ],
  };

  const averageTransactionData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Average Transactions',
        data: [300, 320, 350, 370, 400, 420, 450],
        fill: false,
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
      },
    ],
  };

  

  return (
    <div style={{ display: 'flex', height: '90vh', width: '100vw', marginTop: '50px' }}>
      <SideNav isCollapsed={isCollapsed} toggleSidebar={toggleSidebar} />
      <div
        id="layoutSidenav_content"
        style={{
          flex: 1,
          marginLeft: isCollapsed ? '80px' : '250px',
          transition: 'margin-left 0.3s',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto', // Enable vertical scroll
        }}
      >
        <main style={{ flex: 1, overflow: 'auto' }}>
          <div className="container-fluid px-5">
           <h1></h1><br/>

           <div style={{ marginLeft: '40%' }}>
     <Paper
        sx={{
          padding: '3px 10px', 
          marginBottom: '20px',
          width: 'fit-content',
          backgroundColor: '#c5401f',
          '@keyframes slide-up': {
            '0%': {
              transform: 'translateY(20px)',
              opacity: 0,
            },
            '100%': {
              transform: 'translateY(0)',
              opacity: 1,
            },
          },
          animation: 'slide-up 0.5s ease-in-out',
        }}
      >
        <Typography variant="h5" gutterBottom >
          Admin Dashboard
        </Typography>
      </Paper>

</div>


<MainDash/>
            {/* <div className="row">
              <div className="col-xl-3 col-md-6">
                <div className="card bg-primary text-white mb-4">
                  <div className="card-body">Total Users</div>
                  <div className="card-footer d-flex align-items-center justify-content-between">
                    <span>{totalUsers}</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="card bg-success text-white mb-4">
                  <div className="card-body">New Users</div>
                  <div className="card-footer d-flex align-items-center justify-content-between">
                    <span>{newUsers}</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="card bg-info text-white mb-4">
                  <div className="card-body">Active Users</div>
                  <div className="card-footer d-flex align-items-center justify-content-between">
                    <span>{activeUsers}</span>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="row">
              <div className="col-xl-6">
                <div className="card mb-4">
                  <div className="card-header">
                    <i className="fas fa-chart-line me-1" />
                    Transactions Overview
                  </div>
                  <div className="card-body">
                    <Line data={transactionData} width="100%" height={40} />
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="card mb-4">
                  <div className="card-header">
                    <i className="fas fa-chart-bar me-1" />
                    Revenue Growth
                  </div>
                  <div className="card-body">
                    <Line data={revenueGrowthData} width="100%" height={40} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-6">
                <div className="card mb-4">
                  <div className="card-header">
                    <i className="fas fa-chart-pie me-1" />
                    Average Transactions
                  </div>
                  <div className="card-body">
                    <Line data={averageTransactionData} width="100%" height={40} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card mb-4">
                  <div className="card-header">
                    <i className="fas fa-table me-1" />
                    Initiated Transactions Traffic
                  </div>
                  <TransactionTraffic/>
                </div>
              </div>
            </div>

          </div>
        </main>
      </div>
    </div>
  );
}

export default Main;
