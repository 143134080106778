import React, { useState, useEffect } from 'react';
import { Button, Modal, Box, Typography, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import axios from 'axios';

function AdminRoles() {
  const [roles, setRoles] = useState([]);
  const [openRolesModal, setOpenRolesModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [formData, setFormData] = useState({ name: '', type: '' });
  const [updateFormData, setUpdateFormData] = useState({ Role_id: '', name: '', type: '' });
  const [error, setError] = useState('');

  useEffect(() => {
    if (openRolesModal) {
      fetchRoles();
    }
  }, [openRolesModal]);

  const fetchRoles = async () => {
    const token = localStorage.getItem('admin_token');
    if (!token) {
      setError('Authorization token is missing');
      return;
    }

    try {
      const response = await axios.get('https://api.nkolozi.com/api/Admin/roles', {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      setRoles(response.data.data || []);
    } catch (error) {
      console.error('Failed to fetch roles', error);
      setError('Failed to fetch roles');
    }
  };

  const handleOpenRolesModal = () => setOpenRolesModal(true);
  const handleCloseRolesModal = () => setOpenRolesModal(false);

  const handleOpenAddModal = () => {
    setFormData({ name: '', type: '' });
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => setOpenAddModal(false);

  const handleOpenUpdateModal = (role) => {
    setUpdateFormData({ Role_id: role.id, name: role.name, type: role.type });
    setOpenUpdateModal(true);
  };

  const handleCloseUpdateModal = () => setOpenUpdateModal(false);

  const handleFormChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });
  const handleUpdateFormChange = (e) => setUpdateFormData({ ...updateFormData, [e.target.name]: e.target.value });

  const handleAddRole = async () => {
    const token = localStorage.getItem('admin_token');
    if (!token) {
      setError('Authorization token is missing');
      return;
    }

    try {
      const response = await axios.post('https://api.nkolozi.com/api/Admin/add-role', formData, {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      });
      console.log('Role added successfully', response.data);
      handleCloseAddModal();
      fetchRoles();
    } catch (error) {
      console.error('Failed to add role', error);
      setError(`Error: ${error.response?.data?.message || 'An unexpected error occurred'}`);
    }
  };

  const handleUpdateRole = async () => {
    const token = localStorage.getItem('admin_token');
    if (!token) {
      setError('Authorization token is missing');
      return;
    }

    try {
      const response = await axios.post('https://api.nkolozi.com/api/Admin/update-role', updateFormData, {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      });
      console.log('Role updated successfully', response.data);
      handleCloseUpdateModal();
      fetchRoles();
    } catch (error) {
      console.error('Failed to update role', error);
      setError(`Error: ${error.response?.data?.message || 'An unexpected error occurred'}`);
    }
  };

  const handleDeleteRole = async (Role_id) => {
    const token = localStorage.getItem('admin_token');
    if (!token) {
      setError('Authorization token is missing');
      return;
    }

    try {
      const response = await axios.post('https://api.nkolozi.com/api/Admin/delete-role', { Role_id }, {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      });
      console.log('Role deleted successfully', response.data);
      fetchRoles();
    } catch (error) {
      console.error('Failed to delete role', error);
      setError(`Error: ${error.response?.data?.message || 'An unexpected error occurred'}`);
    }
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleOpenRolesModal} startIcon={<AddCircleOutlineIcon />}>
        Admin Roles
      </Button>
      <Modal open={openRolesModal} onClose={handleCloseRolesModal} aria-labelledby="roles-modal-title" aria-describedby="roles-modal-description">
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="roles-modal-title" variant="h6" component="h2">
            Admin Roles
          </Typography>
          <Button variant="contained" color="primary" onClick={handleOpenAddModal} startIcon={<AddCircleOutlineIcon />} style={{ marginTop: '10px', marginBottom: '20px' }}>
            Add Role
          </Button>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Role Name</TableCell>
                  <TableCell>Role Type</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {roles.map((role) => (
                  <TableRow key={role.id}>
                    <TableCell>{role.name}</TableCell>
                    <TableCell>{role.type}</TableCell>
                    <TableCell>
                      <Button variant="contained" color="primary" onClick={() => handleOpenUpdateModal(role)} style={{ marginRight: '10px' }}>
                        Update
                      </Button>
                      <Button variant="contained" color="secondary" onClick={() => handleDeleteRole(role.id)}>
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Modal open={openAddModal} onClose={handleCloseAddModal} aria-labelledby="add-role-modal-title" aria-describedby="add-role-modal-description">
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
            }}>
              <Typography id="add-role-modal-title" variant="h6" component="h2">
                Add Role
              </Typography>
              <TextField
                label="Role Name"
                name="name"
                value={formData.name}
                onChange={handleFormChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Role Type"
                name="type"
                value={formData.type}
                onChange={handleFormChange}
                fullWidth
                margin="normal"
              />
              <Button variant="contained" color="primary" onClick={handleAddRole} startIcon={<AddCircleOutlineIcon />} style={{ marginTop: '10px' }}>
                Add Role
              </Button>
              {error && (
                <Typography color="error" variant="body2" style={{ marginTop: '10px' }}>
                  {error}
                </Typography>
              )}
            </Box>
          </Modal>
          <Modal open={openUpdateModal} onClose={handleCloseUpdateModal} aria-labelledby="update-role-modal-title" aria-describedby="update-role-modal-description">
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
            }}>
              <Typography id="update-role-modal-title" variant="h6" component="h2">
                Update Role
              </Typography>
              <TextField
                label="Role Name"
                name="name"
                value={updateFormData.name}
                onChange={handleUpdateFormChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Role Type"
                name="type"
                value={updateFormData.type}
                onChange={handleUpdateFormChange}
                fullWidth
                margin="normal"
              />
              <Button variant="contained" color="primary" onClick={handleUpdateRole} startIcon={<AddCircleOutlineIcon />} style={{ marginTop: '10px' }}>
                Update Role
              </Button>
              {error && (
                <Typography color="error" variant="body2" style={{ marginTop: '10px' }}>
                  {error}
                </Typography>
              )}
            </Box>
          </Modal>
        </Box>
      </Modal>
    </div>
  );
}

export default AdminRoles;
