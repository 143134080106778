import React, { useState, useEffect } from 'react';
import { Button, Modal, Box, Typography, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import axios from 'axios';

const TransStatusComponent = () => {
  const [transactionFees, setTransactionFees] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [formData, setFormData] = useState({
    transaction_type: '',
    percentage: '',
    agent_percentage: '',
    transaction_amount_limit: '',
    near_limit_rang: '',
    daily_limit: '', // Correct field name
    monthly_limit: '',
  });
  const [error, setError] = useState('');
  const [userId, setUserId] = useState(null); // State to hold authenticated user ID

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get('https://api.nkolozi.com/api/user/details', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setUserId(response.data.id); // Assuming your API returns user ID in response.data.id
      } catch (error) {
        console.error('Failed to fetch user details', error);
        // Handle error fetching user details
      }
    };

    fetchUserDetails();
  }, []); // Fetch user details on component mount

  useEffect(() => {
    if (userId) {
      fetchTransactionFees();
    }
  }, [userId]); // Fetch transaction fees when userId changes

  const fetchTransactionFees = async () => {
    try {
      const response = await axios.get('https://api.nkolozi.com/api/Admin/transaction-fees', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setTransactionFees(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Failed to fetch transaction fees', error);
      setError(`Failed to fetch transaction fees: ${error.message}`);
      if (error.response) {
        console.error('Error response data:', error.response.data);
      }
    }
  };

  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleOpenAddModal = () => {
    setFormData({
      transaction_type: '',
      percentage: '',
      agent_percentage: '',
      transaction_amount_limit: '',
      near_limit_rang: '',
      daily_limit: '',
      monthly_limit: '',
    });
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => setOpenAddModal(false);

  const handleAddTransactionFee = async () => {
    try {
      const requestData = {
        transaction_type: formData.transaction_type,
        percentage: formData.percentage,
        agent_percentage: formData.agent_percentage,
        transaction_amount_limit: formData.transaction_amount_limit,
        near_limit_rang: formData.near_limit_rang,
        Daily_Limit: formData.daily_limit, // Correct field name
        monthly_limit: formData.monthly_limit,
        created_by: userId,
      };

      const response = await axios.post('https://api.nkolozi.com/api/Admin/add-transaction-fees', requestData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
      console.log('Transaction fee added successfully', response.data);
      handleCloseAddModal();
      fetchTransactionFees();
    } catch (error) {
      console.error('Failed to add transaction fee', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
        setError(`Error: ${error.response.data.message || 'An unexpected error occurred'}`);
      } else {
        setError(`Error: ${error.message || 'An unexpected error occurred'}`);
      }
    }
  };

  const handleUpdateTransactionFee = async (feeId) => {
    try {
      const response = await axios.post('https://api.nkolozi.com/api/Admin/update-transaction-fees', {
        ...formData,
        Transaction_fee_id: feeId,
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
      console.log('Transaction fee updated successfully', response.data);
      fetchTransactionFees();
    } catch (error) {
      console.error('Failed to update transaction fee', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
        setError(`Error: ${error.response.data.message || 'An unexpected error occurred'}`);
      } else {
        setError(`Error: ${error.message || 'An unexpected error occurred'}`);
      }
    }
  };

  const handleDeleteTransactionFee = async (feeId) => {
    try {
      const response = await axios.post('https://api.nkolozi.com/api/Admin/delete-transaction-fees', {
        Transaction_fee_id: feeId,
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
      console.log('Transaction fee deleted successfully', response.data);
      fetchTransactionFees();
    } catch (error) {
      console.error('Failed to delete transaction fee', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
        setError(`Error: ${error.response.data.message || 'An unexpected error occurred'}`);
      } else {
        setError(`Error: ${error.message || 'An unexpected error occurred'}`);
      }
    }
  };

  return (
    <div style={{ marginLeft: '20%', marginTop: '4%', width: '75%' }}>
      <Button variant="contained" color="primary" onClick={handleOpenAddModal} startIcon={<AddCircleOutlineIcon />}>
        Add Fees
      </Button>
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Transaction Type</TableCell>
              <TableCell>Percentage</TableCell>
              <TableCell>Agent Percentage</TableCell>
              <TableCell>Transaction Amount Limit</TableCell>
              <TableCell>Near Limit Range</TableCell>
              <TableCell>Daily Limit</TableCell>
              <TableCell>Monthly Limit</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionFees.map((fee) => (
              <TableRow key={fee.id}>
                <TableCell>{fee.transaction_type}</TableCell>
                <TableCell>{fee.percentage}</TableCell>
                <TableCell>{fee.agent_percentage}</TableCell>
                <TableCell>{fee.transaction_amount_limit}</TableCell>
                <TableCell>{fee.near_limit_rang}</TableCell>
                <TableCell>{fee.Daily_Limit}</TableCell>
                <TableCell>{fee.monthly_limit}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" onClick={() => handleUpdateTransactionFee(fee.id)} style={{ marginRight: '10px' }}>
                    Update
                  </Button>
                  <Button variant="contained" color="secondary" onClick={() => handleDeleteTransactionFee(fee.id)}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {error && (
        <Typography color="error" variant="body2" style={{ marginTop: '10px' }}>
          {error}
        </Typography>
      )}
      <Modal open={openAddModal} onClose={handleCloseAddModal} aria-labelledby="add-modal-title" aria-describedby="add-modal-description">
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="add-modal-title" variant="h6" component="h2">
            Add Transaction Fee
          </Typography>
          <TextField
            label="Transaction Type"
            name="transaction_type"
            value={formData.transaction_type}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Percentage"
            name="percentage"
            value={formData.percentage}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Agent Percentage"
            name="agent_percentage"
            value={formData.agent_percentage}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Transaction Amount Limit"
            name="transaction_amount_limit"
            value={formData.transaction_amount_limit}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Near Limit Range"
            name="near_limit_rang"
            value={formData.near_limit_rang}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Daily Limit"
            name="daily_limit"
            value={formData.daily_limit}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Monthly Limit"
            name="monthly_limit"
            value={formData.monthly_limit}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={handleAddTransactionFee} startIcon={<AddCircleOutlineIcon />} style={{ marginTop: '10px' }}>
            Add Transaction Fee
          </Button>
          {error && (
            <Typography color="error" variant="body2" style={{ marginTop: '10px' }}>
              {error}
            </Typography>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default TransStatusComponent;
