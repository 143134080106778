import React, { useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import SideNav from '../ManagerComp/MSideNav';
import { Link } from 'react-router-dom';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

function MDashComponent() {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const totalUsers = 1200;
  const newUsers = 75;
  const activeUsers = 800;

  const transactionData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Total Transactions',
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
      },
      {
        label: 'Total Reversals',
        data: [5, 4, 6, 3, 2, 2, 1],
        fill: false,
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
      },
    ],
  };

  const revenueGrowthData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Revenue Growth',
        data: [3000, 3200, 3500, 3700, 4000, 4200, 4500],
        fill: false,
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
      },
    ],
  };

  const averageTransactionData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Average Transactions',
        data: [300, 320, 350, 370, 400, 420, 450],
        fill: false,
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
      },
    ],
  };

  const transactionTraffic = [
    {
      id: 1,
      users_id: 101,
      full_name: 'John Doe',
      clients_account: 'CA123456',
      beneficiary_id: 201,
      beneficiary_full_name: 'Jane Smith',
      initial_transfer_amount: 100,
      transaction_fee: 10,
      total_send_amount: 110,
      transaction_fee_id: 301,
      status: 'success',
      pay_out_branch: 'Branch A',
      pay_out_methods: 'Bank Transfer',
      transaction_type: 'bank_transfer',
      transaction_reason: 'Bill Payment',
    },
    // Add more transaction data here
  ];

  return (
    <div style={{ display: 'flex', height: '90vh', width: '100vw', marginTop: '50px' }}>
      <SideNav isCollapsed={isCollapsed} toggleSidebar={toggleSidebar} />
      <div
        id="layoutSidenav_content"
        style={{
          flex: 1,
          marginLeft: isCollapsed ? '80px' : '250px',
          transition: 'margin-left 0.3s',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto', // Enable vertical scroll
        }}
      >
        <main style={{ flex: 1, overflow: 'auto' }}>
          <div className="container-fluid px-5">
            <h1 className="mt-4">Dashboard</h1>

            <div className="row">
              <div className="col-xl-3 col-md-6">
                <div className="card bg-primary text-white mb-4">
                  <div className="card-body">Total Users</div>
                  <div className="card-footer d-flex align-items-center justify-content-between">
                    <span>{totalUsers}</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="card bg-success text-white mb-4">
                  <div className="card-body">New Users</div>
                  <div className="card-footer d-flex align-items-center justify-content-between">
                    <span>{newUsers}</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="card bg-info text-white mb-4">
                  <div className="card-body">Active Users</div>
                  <div className="card-footer d-flex align-items-center justify-content-between">
                    <span>{activeUsers}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-6">
                <div className="card mb-4">
                  <div className="card-header">
                    <i className="fas fa-chart-line me-1" />
                    Transactions Overview
                  </div>
                  <div className="card-body">
                    <Line data={transactionData} width="100%" height={40} />
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="card mb-4">
                  <div className="card-header">
                    <i className="fas fa-chart-bar me-1" />
                    Revenue Growth
                  </div>
                  <div className="card-body">
                    <Line data={revenueGrowthData} width="100%" height={40} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-6">
                <div className="card mb-4">
                  <div className="card-header">
                    <i className="fas fa-chart-pie me-1" />
                    Average Transactions
                  </div>
                  <div className="card-body">
                    <Line data={averageTransactionData} width="100%" height={40} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card mb-4">
                  <div className="card-header">
                    <i className="fas fa-table me-1" />
                    Transaction Traffic
                  </div>
                  <div className="card-body" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>User ID</th>
                          <th>Full Name</th>
                          <th>Client Account</th>
                          <th>Beneficiary ID</th>
                          <th>Beneficiary Full Name</th>
                          <th>Initial Transfer Amount</th>
                          <th>Transaction Fee</th>
                          <th>Total Send Amount</th>
                          <th>Transaction Fee ID</th>
                          <th>Status</th>
                          <th>Pay Out Branch</th>
                          <th>Pay Out Methods</th>
                          <th>Transaction Type</th>
                          <th>Transaction Reason</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactionTraffic.map((transaction) => (
                          <tr key={transaction.id}>
                            <td>{transaction.id}</td>
                            <td>{transaction.users_id}</td>
                            <td>{transaction.full_name}</td>
                            <td>{transaction.clients_account}</td>
                            <td>{transaction.beneficiary_id}</td>
                            <td>{transaction.beneficiary_full_name}</td>
                            <td>{transaction.initial_transfer_amount}</td>
                            <td>{transaction.transaction_fee}</td>
                            <td>{transaction.total_send_amount}</td>
                            <td>{transaction.transaction_fee_id}</td>
                            <td>{transaction.status}</td>
                            <td>{transaction.pay_out_branch}</td>
                            <td>{transaction.pay_out_methods}</td>
                            <td>{transaction.transaction_type}</td>
                            <td>{transaction.transaction_reason}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </main>
      </div>
    </div>
  );
}

export default  MDashComponent
