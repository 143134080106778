import React from 'react'
import SideNav from '../Components/SideNav'
import InitiatedComp from '../Components/InitiatedComp'

function Initiated() {
  return (
    <div>
     <SideNav/>
     <InitiatedComp/> 
    </div>
  )
}

export default Initiated
