import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Form } from 'react-bootstrap';

function TransactionTraffic() {
  const [transactions, setTransactions] = useState([]);
  const [currencies, setCurrencies] = useState({});
  const [clients, setClients] = useState({});
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const token = localStorage.getItem('admin_token');
        const response = await axios.get('https://api.nkolozi.com/api/Admin/currencies', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const currencyMap = {};
        response.data.data.forEach((currency) => {
          currencyMap[currency.id] = currency.name;
        });
        setCurrencies(currencyMap);
      } catch (error) {
        setError('Failed to fetch currencies. Please try again.');
        console.error('Error fetching currencies:', error);
      }
    };

    const fetchClients = async () => {
      try {
        const token = localStorage.getItem('admin_token');
        const response = await axios.get('https://api.nkolozi.com/api/Admin/clients', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const clientMap = {};
        response.data.data.forEach((client) => {
          clientMap[client.id] = `${client.name} ${client.surname}`;
        });
        setClients(clientMap);
      } catch (error) {
        setError('Failed to fetch clients. Please try again.');
        console.error('Error fetching clients:', error);
      }
    };

    const fetchTransactions = async () => {
      try {
        const token = localStorage.getItem('admin_token');
        const response = await axios.get('https://api.nkolozi.com/api/Admin/get-initiated-transactions', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTransactions(response.data.transactions);
      } catch (error) {
        setError('Failed to fetch transactions. Please try again.');
        console.error('Error fetching transactions:', error);
      }
    };

    fetchCurrencies();
    fetchClients();
    fetchTransactions();
  }, []);

  const handleRowClick = (transaction) => {
    setSelectedTransaction(transaction);
  };

  const handleClose = () => {
    setSelectedTransaction(null);
    setReason('');
  };

  const handleAction = async (action) => {
    if (!selectedTransaction) return;
    setLoading(true);
    setError('');

    try {
      const token = localStorage.getItem('admin_token');
      const url =
        action === 'reject'
          ? 'https://api.nkolozi.com/api/Admin/reject-transaction'
          : action === 'suspend'
          ? 'https://api.nkolozi.com/api/Admin/suspend-transaction'
          : 'https://api.nkolozi.com/api/Admin/approve-transaction';

      const payload =
        action === 'reject'
          ? { transaction_id: selectedTransaction.transaction_id, reason }
          : { transaction_id: selectedTransaction.transaction_id };

      await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      handleClose();
    } catch (error) {
      setError('Failed to perform the action. Please try again.');
      console.error('Error performing action:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {error && <p className="text-danger">{error}</p>}
      <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <table className="table table-striped table-bordered">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Transaction ID</th>
              <th scope="col">User</th>
              <th scope="col">Initial Amount</th>
              <th scope="col">Transaction Fee</th>
              <th scope="col">Total Amount</th>
              <th scope="col">Currency</th>
              <th scope="col">Status</th>
              <th scope="col">Created At</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction) => (
              <tr
                key={transaction.id}
                onClick={() => handleRowClick(transaction)}
                style={{ cursor: 'pointer' }}
              >
                <td>{transaction.transaction_id}</td>
                <td style={{color:"blue"}}>{clients[transaction.users_id] || transaction.users_id}</td>
                <td>{transaction.initial_transfer_amount}</td>
                <td>{transaction.transaction_fee}</td>
                <td>{transaction.total_send_amount}</td>
                <td>{currencies[transaction.currency_id] || transaction.currency_id}</td>
                <td style={{backgroundColor:"greenyellow"}}>{transaction.status}</td>
                <td>
                  {new Date(transaction.created_at).toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {selectedTransaction && (
        <Modal show={true} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Transaction Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Action</h5>
            <Button
              variant="danger"
              onClick={() => handleAction('reject')}
              disabled={loading}
              className="me-2"
            >
              {loading && selectedTransaction.transaction_id === 'reject'
                ? 'Rejecting...'
                : 'Reject'}
            </Button>
            <Button
              variant="warning"
              onClick={() => handleAction('suspend')}
              disabled={loading}
            >
              {loading && selectedTransaction.transaction_id === 'suspend'
                ? 'Suspending...'
                : 'Suspend'}
            </Button>
            <Button
              variant="success"
              onClick={() => handleAction('proceed')}
              disabled={loading}
            >
              {loading && selectedTransaction.transaction_id === 'proceed'
                ? 'Proceeding...'
                : 'Proceed'}
            </Button>
            <hr />
            {selectedTransaction.transaction_id === 'reject' && (
              <Form.Group>
                <Form.Label>Reason for Rejection</Form.Label>
                <Form.Control
                  type="text"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  placeholder="Enter reason"
                />
              </Form.Group>
            )}
            <p>
              <strong>Transaction ID:</strong> {selectedTransaction.transaction_id}
            </p>
            <p>
              <strong>User:</strong> {clients[selectedTransaction.users_id] || selectedTransaction.users_id}
            </p>
            <p>
              <strong>Initial Amount:</strong> {selectedTransaction.initial_transfer_amount}
            </p>
            <p>
              <strong>Transaction Fee:</strong> {selectedTransaction.transaction_fee}
            </p>
            <p>
              <strong>Total Amount:</strong> {selectedTransaction.total_send_amount}
            </p>
            <p>
              <strong>Currency:</strong> {currencies[selectedTransaction.currency_id] || selectedTransaction.currency_id}
            </p>
            <p>
              <strong>USD Value:</strong> {selectedTransaction.transa_usd_value}
            </p>
            <p>
              <strong>Status:</strong> {selectedTransaction.status}
            </p>
            <p>
              <strong>Pay Out Branch:</strong> {selectedTransaction.pay_out_branch}
            </p>
            <p>
              <strong>Pay Out Method:</strong> {selectedTransaction.pay_out_methods}
            </p>
            <p>
              <strong>Transaction Type:</strong> {selectedTransaction.transaction_type}
            </p>
            <p>
              <strong>Transaction Reason:</strong> {selectedTransaction.transaction_reason}
            </p>
            <p>
              <strong>Created At:</strong>{' '}
              {new Date(selectedTransaction.created_at).toLocaleDateString('en-GB', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })}
            </p>
            <p>
              <strong>Updated At:</strong>{' '}
              {new Date(selectedTransaction.updated_at).toLocaleDateString('en-GB', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })}
            </p>
            {/* Add other fields here as needed */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default TransactionTraffic;
