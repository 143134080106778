import React from 'react'
import DepositsComp from '../Components/DepositsComp'
import SideNav from '../Components/SideNav'

function Deposits() {
  return (
    <>
    <SideNav/>
    <DepositsComp/>
</>
  )
}

export default Deposits
