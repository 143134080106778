import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import SuspendedTransComponent from '../Components/SuspendedTransComponent'

function SuspendedTrans() {
  return (
    <div>
      
      <SideNav/>
      <SuspendedTransComponent/>
    </div>
  )
}

export default SuspendedTrans
