import React from 'react'
import { Link } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import BadgeIcon from '@mui/icons-material/Badge';
function MSideNav() {
  return (
    <div id="layoutSidenav" >
    <div id="layoutSidenav_nav" style={{ position: 'fixed', top: 58, left: 0, height: '100vh', width: '250px' ,  overflow: 'auto'}}>
      <nav className="sb-sidenav accordion sb-sidenav-light" id="sidenavAccordion">
        <div className="sb-sidenav-menu">
          <div className="nav">
            
            <Link to="/dashboard" className="nav-link">
            <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt" /></div>
            
            Dashboard
          </Link>

            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts">
              <div className="sb-nav-link-icon"><i className="fas fa-columns" /></div>
              Transactions
              <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down" /></div>
            </a>
            <div className="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
              <nav className="sb-sidenav-menu-nested nav">
              <Link to="/transStatus" className="nav-link">Transaction status</Link>
      <Link to="/highRisk" className="nav-link">High risk transactions</Link>
      <Link to="/suspended" className="nav-link">Suspended transactions</Link>
              </nav>
            </div>
            <Link to="/agents" className="nav-link">
            <div className="sb-nav-link-icon"><i className="" /></div>
            <SupportAgentIcon />
            Agents
            
          </Link>

 <Link to="/employees" className="nav-link">
            <div className="sb-nav-link-icon"><i className="" /></div>
            <BadgeIcon />
            Employees
            
          </Link>

           
           
            <div className="collapse" id="collapsePages" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion">
              <nav className="sb-sidenav-menu-nested nav accordion" id="sidenavAccordionPages">
                <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseAuth" aria-expanded="false" aria-controls="pagesCollapseAuth">
                  Fees and Rates
                  <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down" /></div>
                </a>

                
                <div className="collapse" id="pagesCollapseAuth" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                  <nav className="sb-sidenav-menu-nested nav">
                  <Link to="/fees" className="nav-link">Transaction Fees</Link>
                  <Link to="/exchangeRates" className="nav-link">Exchange Rates</Link>
                  </nav> 
                </div>
                <Link to="/risk" className="nav-link">
            <div className="sb-nav-link-icon"><i className="fas fa-chart-area" /></div>
            Risk Management
          </Link>
            
              
              </nav>
            </div>
            
       
            <a className="nav-link" href="#" data-bs-toggle="collapse" data-bs-target="#complianceRegulatory" aria-expanded="false" aria-controls="complianceRegulatory">
      <div className="sb-nav-link-icon"><i className="fas fa-shield-alt" /></div>
      Compliance and Regulatory
      <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down" /></div>
    </a>
   
    <div className="collapse" id="complianceRegulatory" aria-labelledby="headingThree" data-bs-parent="#sidenavAccordionPages">
      <nav className="sb-sidenav-menu-nested nav">
      <Link to="/regulatory" className="nav-link">Regulatory Guidelines</Link>
      <Link to="/complience" className="nav-link">Compliance Policies</Link>
      </nav>
    </div>

    <Link to="/settings" className="nav-link">
        <SettingsIcon />
        Settings
      </Link>
    
          </div>
        </div>
       
      </nav>
    </div>
  </div>
  

  )
}

export default MSideNav
