import React from 'react'
import SideNav from '../Components/SideNav'
import ClientsComp from '../Components/ClientsComp'

function Clients() {
  return (
   <>
    <SideNav/>
    <ClientsComp/>
   </>
  )
}

export default Clients
