import React from 'react';
import { Container, Typography, Grid, TextField, Button, Paper, MenuItem } from '@mui/material';
import { Box } from '@mui/system';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

function FeesComponent() {
  return (
    <Container maxWidth="md" style={{ marginTop: '5%' , marginLeft: '20%', overflow: 'auto',  height: '100vh'}}>
      <Paper elevation={3} style={{ padding: '10%' }}>
        <Box display="flex" alignItems="center" justifyContent="center" mb={3}>
          <MonetizationOnIcon style={{ marginRight: '10px' }} />
          <Typography variant="h4" align="center">
            Transaction Fees
          </Typography>
        </Box>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label="Originating Country"
                name="originatingCountry"
                variant="outlined"
                required
              >
                <MenuItem value="Botswana">Botswana</MenuItem>
                <MenuItem value="Zimbabwe">Zimbabwe</MenuItem>
                <MenuItem value="South Africa">South Africa</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label="Destination Country"
                name="destinationCountry"
                variant="outlined"
                required
              >
                <MenuItem value="Botswana">Botswana</MenuItem>
                <MenuItem value="Zimbabwe">Zimbabwe</MenuItem>
                <MenuItem value="South Africa">South Africa</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Fee Value"
                name="feeValue"
                type="number"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Minimum Transaction Amount"
                name="minTransactionAmount"
                type="number"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Maximum Transaction Amount"
                name="maxTransactionAmount"
                type="number"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Additional Notes"
                name="notes"
                multiline
                rows={4}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}

export default FeesComponent;
