import React from 'react'
import SideNav from '../Components/SideNav'
import RbzComp from '../Components/RbzComp'

const Rbz = () => {
  return (
    <div>
        <SideNav/>
        <RbzComp/>
    </div>
  )
}

export default Rbz