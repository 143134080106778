import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Pagination, FormControl, InputGroup } from 'react-bootstrap';
import { Paper, Typography } from '@mui/material';

const TransactionsComp = () => {
  const [transactions, setTransactions] = useState([]);
  const [clients, setClients] = useState([]);
  const [agents, setAgents] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState(''); // New state for the search term

  const token = localStorage.getItem('admin_token');

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const [transactionsResponse, clientsResponse, agentsResponse, currenciesResponse] = await Promise.all([
          axios.get('https://api.nkolozi.com/api/Admin/transactions', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get('https://api.nkolozi.com/api/Admin/clients', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get('https://api.nkolozi.com/api/Admin/agents', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get('https://api.nkolozi.com/api/Admin/currencies', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        ]);

        setTransactions(transactionsResponse.data.data);
        setClients(clientsResponse.data.data);
        setAgents(agentsResponse.data.data);
        setCurrencies(currenciesResponse.data.data);
        setTotalPages(Math.ceil(transactionsResponse.data.data.length / 10)); // Assuming 10 transactions per page
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchTransactions();
  }, [token]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getUserName = (userId) => {
    const userIdStr = String(userId);
    const client = clients.find(client => String(client.id) === userIdStr);
    if (client) return `${client.name} ${client.surname}`;
    
    const agent = agents.find(agent => String(agent.id) === userIdStr);
    if (agent) return `${agent.name} ${agent.surname}`;
  
    return 'Unknown User';
  };

  const getCurrencyName = (currencyId) => {
    const currency = currencies.find(currency => currency.id === currencyId);
    return currency ? currency.name : 'Unknown Currency';
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to the first page on a new search
  };

  const filteredTransactions = transactions.filter((transaction) => {
    const userName = getUserName(transaction.users_id).toLowerCase();
    return userName.includes(searchTerm.toLowerCase());
  });

  const renderTableRows = () => {
    const startIndex = (currentPage - 1) * 10;
    const endIndex = startIndex + 10;
    return filteredTransactions.slice(startIndex, endIndex).map((transaction) => (
      <tr key={transaction.id}>
        <td>{transaction.transaction_id}</td>
        <td >{getUserName(transaction.users_id)}</td>
        <td>{transaction.initial_transfer_amount}</td>
        <td>{transaction.transaction_fee}</td>
        <td>{transaction.total_send_amount}</td>
        <td>{getCurrencyName(transaction.currency_id)}</td>
        <td>
          <span style={{
            backgroundColor:
              transaction.status === 'initiated' ? 'lime' :
              transaction.status === 'approved' ? 'green' :
              transaction.status === 'suspended' ? 'orange' :
              transaction.status === 'rejected' ? 'red' :
              transaction.status === 'completed' ? 'blue' : // Choose a suitable color for 'completed'
              'black' // Default color if status does not match any condition
          }}>
            {transaction.status}
          </span>
        </td>
        <td>{formatDate(transaction.created_at)}</td>
        <td>{transaction.transaction_reason}</td>
       
        <span style={{
            color:
             
              transaction.flag === 'normal' ? 'blue' :
              transaction.flag === 'structured' ? 'yellow' :
              transaction.flag === 'unusual' ? 'red' :
              'black' 
          }}>
            {transaction.flag}
          </span>
      </tr>
    ));
  };

  return (
    <div style={{ marginLeft: '20%', marginTop: '4%', width: '75%', position: 'relative' }}>
      <div style={{ marginLeft: '40%' }}>
        <Paper
          sx={{
            padding: '3px 10px', 
            marginBottom: '20px',
            width: 'fit-content',
            backgroundColor: '#c5401f',
            '@keyframes slide-up': {
              '0%': {
                transform: 'translateY(20px)',
                opacity: 0,
              },
              '100%': {
                transform: 'translateY(0)',
                opacity: 1,
              },
            },
            animation: 'slide-up 0.5s ease-in-out',
          }}
        >
          <Typography variant="h5" gutterBottom>
            All Transactions
          </Typography>
        </Paper>
      </div>

      <InputGroup className="mb-3" style={{ margin: '20px 0' }}>
        <FormControl
          placeholder="Search by Name or Surname"
          aria-label="Search by Name or Surname"
          aria-describedby="basic-addon2"
          value={searchTerm}
          onChange={handleSearch}
        />
      </InputGroup>

      <div style={{ overflowX: 'auto', maxHeight: '70vh' }}>
        <Table striped bordered hover className='table-responsive'>
          <thead>
            <tr>
              <th>Transaction ID</th>
              <th>User</th>
              <th>Initial Amount</th>
              <th>Transaction Fee</th>
              <th>Total Amount</th>
              <th>Currency</th>
              <th>Status</th>
              <th>Created At</th>
              <th>Reason</th>
              <th>Flag</th>
            </tr>
          </thead>
          <tbody>
            {renderTableRows()}
          </tbody>
        </Table>
        <Pagination className="justify-content-center">
          {Array.from({ length: totalPages }, (_, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>
    </div>
  );
};

export default TransactionsComp;
