import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import RegulatoryComponent from '../Components/RegulatoryComponent'

function Regulatory() {
  return (
    <div>
      
      <SideNav />
      <RegulatoryComponent/>
    </div>
  )
}

export default Regulatory
